/**
 * Utility to measure and cache the browser's scrollbar width
 */

// Singleton to store the scrollbar width
let scrollbarWidthCache: number | null = null;

/**
 * Measures the browser's scrollbar width by creating a temporary hidden element
 * This only runs the calculation once and then caches the result
 */
export const getScrollbarWidth = (): number => {
  // Return cached value if already calculated
  if (scrollbarWidthCache !== null) {
    return scrollbarWidthCache;
  }

  // Only run in browser environment
  if (typeof document === 'undefined') {
    return 0;
  }

  // Create a temporary div with a forced scrollbar
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  document.body.appendChild(outer);

  // Create inner div
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculate the width difference
  scrollbarWidthCache = outer.offsetWidth - inner.offsetWidth;

  // Clean up
  if (outer.parentNode) {
    outer.parentNode.removeChild(outer);
  }

  return scrollbarWidthCache;
};

// Initialize the scrollbar width on load
if (typeof window !== 'undefined') {
  // Use requestAnimationFrame to ensure the DOM is ready
  window.requestAnimationFrame(() => {
    getScrollbarWidth();
  });
} 