import { useEffect, useState } from 'react';
import type { LucideIcon } from 'lucide-react';
import { loadIcon, type IconName } from '../lib/iconLoader';

interface IconProps {
  name: IconName;
  size?: number;
  className?: string;
  strokeWidth?: number;
  fill?: string;
}

export function Icon({ name, size = 24, className = '', strokeWidth = 2, fill = 'none' }: IconProps) {
  const [IconComponent, setIconComponent] = useState<LucideIcon | null>(null);

  useEffect(() => {
    loadIcon(name).then(setIconComponent);
  }, [name]);

  if (!IconComponent) {
    return null;
  }

  return (
    <IconComponent
      size={size}
      className={className}
      strokeWidth={strokeWidth}
      fill={fill}
    />
  );
} 