import { useState, useCallback, useEffect } from 'react';
import { useQueryStates, createParser } from 'nuqs';
import { DEFAULT_QUERY_STATE, QueryState, SortOption } from '../types/queryState';
import type { EquipmentCategory } from '../types/api';
import { equipmentCategories } from '../types/api';
import { RANGE_CONFIGS } from '../shared/range-config.js';

// Import nuqs parsers directly
import { 
  parseAsArrayOf, 
  parseAsString, 
  parseAsBoolean, 
  parseAsInteger, 
  parseAsStringEnum, 
  parseAsJson 
} from 'nuqs';

// Simple validation functions for JSON parsers
const validatePriceRange = (value: unknown): [number, number] => {
  if (Array.isArray(value) && value.length === 2 && 
      typeof value[0] === 'number' && typeof value[1] === 'number') {
    return value as [number, number];
  }
  return DEFAULT_QUERY_STATE.priceRange;
};

const validateYearRange = (value: unknown): [number, number] => {
  if (Array.isArray(value) && value.length === 2 && 
      typeof value[0] === 'number' && typeof value[1] === 'number') {
    return value as [number, number];
  }
  return DEFAULT_QUERY_STATE.yearRange;
};

const validateEngineTimeRange = (value: unknown): [number, number] => {
  if (Array.isArray(value) && value.length === 2 && 
      typeof value[0] === 'number' && typeof value[1] === 'number') {
    return value as [number, number];
  }
  return DEFAULT_QUERY_STATE.engineTimeRange;
};

const validateUsefulLoadRange = (value: unknown): [number, number] => {
  if (Array.isArray(value) && value.length === 2 && 
      typeof value[0] === 'number' && typeof value[1] === 'number') {
    return value as [number, number];
  }
  return DEFAULT_QUERY_STATE.usefulLoadRange;
};

// Create a custom parser for equipment that only includes true values in the URL
const parseEquipment = createParser({
  parse: (value: string | null): Record<EquipmentCategory, boolean> => {
    // Start with default (all false) values
    const result = { ...DEFAULT_QUERY_STATE.equipment };
    
    // If no value in URL, return defaults
    if (!value) return result;
    
    try {
      // Parse the URL value
      const parsed = JSON.parse(value);
      
      // If it's an object, apply the true values to our result
      if (parsed && typeof parsed === 'object') {
        Object.entries(parsed).forEach(([key, val]) => {
          if (key in result && val === true) {
            result[key as EquipmentCategory] = true;
          }
        });
      }
      
      return result;
    } catch (e) {
      // If parsing fails, return defaults
      return result;
    }
  },
  serialize: (value: Record<EquipmentCategory, boolean>): string | null => {
    // Create an object containing only the true values
    const trueValuesOnly = Object.entries(value)
      .filter(([_, isSelected]) => isSelected)
      .reduce((acc, [key]) => {
        acc[key] = true;
        return acc;
      }, {} as Record<string, boolean>);
    
    // Only return a string if there are true values, otherwise return null
    // (this ensures the parameter isn't shown in the URL when all values are default/false)
    return Object.keys(trueValuesOnly).length > 0
      ? JSON.stringify(trueValuesOnly)
      : null;
  }
}).withDefault(DEFAULT_QUERY_STATE.equipment);

// Create lowercase parsers for string fields
const parseAsLowercaseString = createParser({
  parse: (value: string | null): string => {
    return value ? value.toLowerCase() : '';
  },
  serialize: (value: string): string | null => {
    return value || null;
  }
}).withDefault('');

// Create lowercase parsers for string arrays
const parseAsLowercaseStringArray = parseAsArrayOf(
  createParser({
    parse: (value: string | null): string => {
      return value ? value.toLowerCase() : '';
    },
    serialize: (value: string): string | null => {
      return value || null;
    }
  })
).withDefault([]);

export function useListingQueryState() {
  const [queryState, setQueryState] = useQueryStates({
    // Search and filters
    search: parseAsString.withDefault(DEFAULT_QUERY_STATE.search),
    makes: parseAsLowercaseStringArray,
    modelFamilies: parseAsLowercaseStringArray,
    models: parseAsLowercaseStringArray,
    priceRange: parseAsJson(validatePriceRange).withDefault(DEFAULT_QUERY_STATE.priceRange),
    yearRange: parseAsJson(validateYearRange).withDefault(DEFAULT_QUERY_STATE.yearRange),
    engineTimeRange: parseAsJson(validateEngineTimeRange).withDefault(DEFAULT_QUERY_STATE.engineTimeRange),
    usefulLoadRange: parseAsJson(validateUsefulLoadRange).withDefault(DEFAULT_QUERY_STATE.usefulLoadRange),
    showZeroPrice: parseAsBoolean.withDefault(DEFAULT_QUERY_STATE.showZeroPrice),
    showSold: parseAsBoolean.withDefault(DEFAULT_QUERY_STATE.showSold),
    equipment: parseEquipment,
    
    // New filters
    condition: parseAsLowercaseString,
    saleStatus: parseAsLowercaseString,
    intendedUse: parseAsLowercaseStringArray,
    category: parseAsLowercaseString,
    
    // Pagination
    page: parseAsInteger.withDefault(DEFAULT_QUERY_STATE.page),
    itemsPerPage: parseAsInteger.withDefault(DEFAULT_QUERY_STATE.itemsPerPage),
    
    // Sort and view
    sort: parseAsStringEnum<SortOption>(['relevance', 'price-asc', 'price-desc', 'year-asc', 'year-desc', 'date-listed', 'updated-desc', 'updated-asc']).withDefault(DEFAULT_QUERY_STATE.sort)
  }, {
    history: 'replace',
    throttleMs: 100
  });

  // Check if any equipment is active
  const hasActiveEquipment = Object.values(queryState.equipment).some(Boolean);

  // Log equipment state changes in development
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      // Removed console log
    }
  }, [queryState.equipment]);

  const isFiltered = Boolean(
    queryState.search ||
    queryState.makes.length ||
    queryState.modelFamilies.length ||
    queryState.models.length ||
    queryState.showZeroPrice ||
    queryState.showSold !== DEFAULT_QUERY_STATE.showSold ||
    queryState.priceRange[0] !== DEFAULT_QUERY_STATE.priceRange[0] ||
    queryState.priceRange[1] !== DEFAULT_QUERY_STATE.priceRange[1] ||
    queryState.yearRange[0] !== DEFAULT_QUERY_STATE.yearRange[0] ||
    queryState.yearRange[1] !== DEFAULT_QUERY_STATE.yearRange[1] ||
    queryState.engineTimeRange[0] !== DEFAULT_QUERY_STATE.engineTimeRange[0] ||
    queryState.engineTimeRange[1] !== DEFAULT_QUERY_STATE.engineTimeRange[1] ||
    queryState.usefulLoadRange[0] !== DEFAULT_QUERY_STATE.usefulLoadRange[0] ||
    queryState.usefulLoadRange[1] !== DEFAULT_QUERY_STATE.usefulLoadRange[1] ||
    hasActiveEquipment ||
    // Check new filters
    queryState.condition !== DEFAULT_QUERY_STATE.condition ||
    queryState.saleStatus !== DEFAULT_QUERY_STATE.saleStatus ||
    queryState.intendedUse.length !== DEFAULT_QUERY_STATE.intendedUse.length ||
    queryState.category !== DEFAULT_QUERY_STATE.category
  );

  const clearFilters = () => {
    setQueryState(prev => ({
      ...prev,
      search: '',
      makes: [],
      modelFamilies: [],
      models: [],
      priceRange: DEFAULT_QUERY_STATE.priceRange,
      yearRange: DEFAULT_QUERY_STATE.yearRange,
      engineTimeRange: DEFAULT_QUERY_STATE.engineTimeRange,
      usefulLoadRange: DEFAULT_QUERY_STATE.usefulLoadRange,
      showZeroPrice: false,
      showSold: false,
      equipment: DEFAULT_QUERY_STATE.equipment,
      // Clear new filters
      condition: DEFAULT_QUERY_STATE.condition,
      saleStatus: DEFAULT_QUERY_STATE.saleStatus,
      intendedUse: DEFAULT_QUERY_STATE.intendedUse,
      category: DEFAULT_QUERY_STATE.category,
      page: 1
    }));
  };

  // Create a callback for changing equipment filters
  const setEquipment = useCallback((equipment: Partial<Record<EquipmentCategory, boolean>>) => {
    // Merge with existing equipment values
    const newEquipment = {
      ...queryState.equipment as Record<EquipmentCategory, boolean>,
      ...equipment
    };
    
    // Debug equipment state
    if (process.env.NODE_ENV === 'development') {
      // Removed console log
    }
    
    // Update query state with new equipment values
    setQueryState({
      ...queryState,
      equipment: newEquipment
    });
  }, [queryState, setQueryState]);

  return {
    queryState,
    setQueryState,
    isFiltered,
    clearFilters,
    setEquipment
  };
} 