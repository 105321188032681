import { useEffect, useRef, useCallback } from 'react';
import './PropLoaderAnimation.css';

// Use a single sprite image with all blur states
const PROP_SPRITE = "/props/prop-blur-100x3.png";

// Canvas configuration
const CANVAS = {
  WIDTH: 100,
  HEIGHT: 100,
  SPRITE_POSITIONS: [0, 100, 200] // x-positions of each blur level in the sprite
};

// Animation configuration
const ANIMATION_CONFIG = {
  INITIAL_DELAY: 0,
  STARTUP_DURATION: 2000,
  MAX_SPEED: 2400,
  BLUR1_SPEED: 500,
  BLUR2_SPEED: 1400,
  MAX_DELTA_TIME: 0.064,
  COMPLETION_DELAY: 500 // Time to wait at full speed after loading completes
};

interface PropLoaderAnimationProps {
  isLoading: boolean;
  text?: string;
  onAnimationComplete?: () => void; // New callback for animation completion
}

export function PropLoaderAnimation({ 
  isLoading, 
  text = "Loading listings...",
  onAnimationComplete 
}: PropLoaderAnimationProps) {
  // Refs for canvas and animation
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const rafIdRef = useRef<number | null>(null);
  const spriteImageRef = useRef<HTMLImageElement | null>(null);
  const spriteLoadedRef = useRef(false);
  const animationCompleteRef = useRef(false);
  const finalDelayStartedRef = useRef(false);
  const finalDelayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  
  // Animation state
  const stateRef = useRef({
    currentRotation: 0,
    currentSpeed: 0,
    animationStartTime: 0,
    lastTimestamp: 0,
    isContinuous: false,
    currentBlurLevel: 0,
    hasReachedFinalBlur: false
  });

  // Cleanup function to cancel all animations and timeouts
  const cleanupAnimation = useCallback(() => {
    if (rafIdRef.current) {
      cancelAnimationFrame(rafIdRef.current);
      rafIdRef.current = null;
    }
    
    if (finalDelayTimeoutRef.current) {
      clearTimeout(finalDelayTimeoutRef.current);
      finalDelayTimeoutRef.current = null;
    }
  }, []);

  // Load the sprite image
  const loadSpriteImage = useCallback((): Promise<boolean> => {
    return new Promise((resolve) => {
      // Create image element if not already created
      if (!spriteImageRef.current) {
        const img = new Image();
        img.crossOrigin = "anonymous";
        
        // Set handlers
        img.onload = () => {
          spriteLoadedRef.current = true;
          resolve(true);
        };
        
        img.onerror = () => {
          console.warn('Failed to load propeller sprite');
          resolve(false);
        };
        
        // Set timeout to prevent hanging
        const timeout = setTimeout(() => {
          console.warn('Propeller sprite loading timed out');
          resolve(false);
        }, 3000);
        
        // Trigger loading
        img.src = PROP_SPRITE;
        
        // If already loaded from cache
        if (img.complete) {
          clearTimeout(timeout);
          spriteLoadedRef.current = true;
          resolve(true);
        }
        
        // Store the image reference
        spriteImageRef.current = img;
      } else {
        // Image already loaded
        resolve(spriteLoadedRef.current);
      }
    });
  }, []);
  
  // Simple function to determine blur level based on speed
  const getBlurLevelForSpeed = useCallback((speed: number): number => {
    if (speed < ANIMATION_CONFIG.BLUR1_SPEED) {
      return 0;
    } else if (speed < ANIMATION_CONFIG.BLUR2_SPEED) {
      return 1;
    } else {
      return 2;
    }
  }, []);
  
  // Function to handle the transition completion with delay
  const handleCompletionWithDelay = useCallback(() => {
    const state = stateRef.current;
    
    // If we've reached final blur level but haven't started final delay
    if (state.currentBlurLevel === 2 && !finalDelayStartedRef.current) {
      // Mark that we've started the final delay
      finalDelayStartedRef.current = true;
      
      // Wait for the delay before completing
      finalDelayTimeoutRef.current = setTimeout(() => {
        // Mark animation as fully complete
        animationCompleteRef.current = true;
        
        // Notify parent component if callback provided
        if (onAnimationComplete) {
          onAnimationComplete();
        }
      }, ANIMATION_CONFIG.COMPLETION_DELAY);
    }
  }, [onAnimationComplete]);
  
  // Draw the propeller to the canvas
  const drawPropeller = useCallback(() => {
    const canvas = canvasRef.current;
    const img = spriteImageRef.current;
    if (!canvas || !img || !spriteLoadedRef.current) return;
    
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    
    const state = stateRef.current;
    const blurLevel = state.currentBlurLevel;
    
    // Clear canvas
    ctx.clearRect(0, 0, CANVAS.WIDTH, CANVAS.HEIGHT);
    
    // Save context for transformations
    ctx.save();
    
    // Move to center for rotation
    ctx.translate(CANVAS.WIDTH / 2, CANVAS.HEIGHT / 2);
    
    // Apply rotation
    ctx.rotate((state.currentRotation * Math.PI) / 180);
    
    // Draw the appropriate part of the sprite based on blur level
    ctx.drawImage(
      img,
      CANVAS.SPRITE_POSITIONS[blurLevel], 0, // source x,y
      CANVAS.WIDTH, CANVAS.HEIGHT,          // source width,height
      -CANVAS.WIDTH / 2, -CANVAS.HEIGHT / 2, // destination x,y (centered)
      CANVAS.WIDTH, CANVAS.HEIGHT            // destination width,height
    );
    
    // Restore context
    ctx.restore();
  }, []);

  // Main animation frame function
  const animateFrame = useCallback((timestamp: number) => {
    const state = stateRef.current;
    
    if (!state.animationStartTime) {
      state.animationStartTime = timestamp;
      state.lastTimestamp = timestamp;
    }
    
    const elapsed = timestamp - state.animationStartTime;
    const progress = Math.min(1, elapsed / ANIMATION_CONFIG.STARTUP_DURATION);
    
    // Handle startup acceleration or continuous rotation
    if (!state.isContinuous) {
      // Startup phase - accelerate to full speed
      const easedProgress = progress < 0.5 
        ? 2 * progress * progress // Ease-in quad for first half
        : -1 + (4 - 2 * progress) * progress; // Ease-out quad for second half
      
      // Calculate current speed with smoother acceleration
      state.currentSpeed = ANIMATION_CONFIG.MAX_SPEED * easedProgress;
      
      // Check if we've reached continuous phase
      if (progress >= 1) {
        state.isContinuous = true;
      }
    } else {
      // Continuous phase - maintain max speed
      state.currentSpeed = ANIMATION_CONFIG.MAX_SPEED;
    }
    
    // Update rotation based on current speed
    const deltaTime = Math.min((timestamp - state.lastTimestamp) / 1000, ANIMATION_CONFIG.MAX_DELTA_TIME);
    state.lastTimestamp = timestamp;
    
    // Progress the rotation by the current speed
    const deltaAngle = state.currentSpeed * deltaTime;
    state.currentRotation = (state.currentRotation + deltaAngle) % 360;
    
    // Set blur level based on speed (no transitions, just direct switch)
    const newBlurLevel = getBlurLevelForSpeed(state.currentSpeed);
    state.currentBlurLevel = newBlurLevel;
    
    // Check if we've reached the final blur level (for completion)
    if (newBlurLevel === 2 && !isLoading) {
      handleCompletionWithDelay();
    }
    
    // Draw the propeller
    drawPropeller();
    
    // Continue animation if still loading or the animation hasn't completed its final phase
    if (isLoading || !animationCompleteRef.current) {
      rafIdRef.current = requestAnimationFrame(animateFrame);
    }
  }, [drawPropeller, getBlurLevelForSpeed, handleCompletionWithDelay, isLoading]);

  // Effect to handle animation based on loading state
  useEffect(() => {
    // Reset completion tracking when loading state changes
    if (isLoading) {
      animationCompleteRef.current = false;
      finalDelayStartedRef.current = false;
    }
    
    // Initialize canvas dimensions
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    // Set canvas dimensions and prevent blurry rendering
    canvas.width = CANVAS.WIDTH;
    canvas.height = CANVAS.HEIGHT;
    
    const state = stateRef.current;
    
    // Reset state for animation start if we're starting fresh
    if (isLoading && !rafIdRef.current) {
      state.currentRotation = 0;
      state.currentSpeed = 0;
      state.animationStartTime = 0;
      state.lastTimestamp = 0;
      state.isContinuous = false;
      state.currentBlurLevel = 0;
      state.hasReachedFinalBlur = false;
    }
    
    // Load image and start animation
    const prepareAndStart = async () => {
      await loadSpriteImage();
      
      // Only start a new animation if one isn't already running
      if (!rafIdRef.current) {
        rafIdRef.current = requestAnimationFrame(animateFrame);
      }
    };
    
    // Start or continue animation based on loading state
    if (isLoading || !animationCompleteRef.current) {
      prepareAndStart();
    } else {
      // If loading is complete and animation has finished its final phase, clean up
      cleanupAnimation();
    }
    
    // Cleanup animation on unmount
    return cleanupAnimation;
  }, [animateFrame, cleanupAnimation, isLoading, loadSpriteImage]);

  return (
    <div className="prop-wrapper flex flex-col items-center justify-center gap-3 w-full h-full">
      <div className="prop-container">
        <canvas
          ref={canvasRef}
          className="prop-canvas"
          width={CANVAS.WIDTH}
          height={CANVAS.HEIGHT}
          style={{
            width: '100px',
            height: '100px'
          }}
        />
      </div>
      
      {text && (
        <p className="text-base font-medium text-neutral-900 dark:text-neutral-100 drop-shadow-sm relative z-[80]">
          {text}
        </p>
      )}
    </div>
  );
} 