import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from './Icon';
import { api } from '../api/client';
import { ProductListing } from '../types/api';
import { ListingCard } from './ListingCard';
import { FavoritesManager } from '../utils/favorites';
import { ImageModal } from './ImageModal';
import { PropLoaderAnimation } from './PropLoaderAnimation';

export const FavoritesPage = () => {
  const [favoriteListings, setFavoriteListings] = useState<ProductListing[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentListing, setCurrentListing] = useState<ProductListing | null>(null);
  const [modalIndex, setModalIndex] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  // Fetch favorites when component mounts
  useEffect(() => {
    const fetchFavorites = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        const favoriteIds = FavoritesManager.getFavorites();
        
        if (favoriteIds.length === 0) {
          setFavoriteListings([]);
          setIsLoading(false);
          return;
        }
        
        // We'll fetch each listing by ID
        const listingPromises = favoriteIds.map(id => 
          api.listings.getById(id)
            .then(response => response.data)
            .catch(error => {
              console.error(`Error fetching listing ${id}:`, error);
              return null;
            })
        );
        
        const listings = await Promise.all(listingPromises);
        
        // Filter out any null responses (failed fetches)
        setFavoriteListings(listings.filter(listing => listing !== null) as ProductListing[]);
      } catch (error) {
        console.error('Error fetching favorite listings:', error);
        setError('Failed to load favorite listings. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchFavorites();
  }, []);

  const handleImageClick = (listing: ProductListing, index: number) => {
    setCurrentListing(listing);
    setModalIndex(index);
    setIsModalOpen(true);
  };

  // Update favorites list when a favorite is removed
  const handleFavoriteRemoved = () => {
    const favoriteIds = FavoritesManager.getFavorites();
    setFavoriteListings(prevListings => 
      prevListings.filter(listing => favoriteIds.includes(listing.aircraftId || listing._id))
    );
  };

  return (
    <div className="min-h-screen bg-white dark:bg-neutral-900">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-4">
            <button
              className="inline-flex items-center gap-2 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Icon name="ArrowLeft" size={16} />
              Back
            </button>
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
              My Favorites
            </h1>
          </div>
          
          {favoriteListings.length > 0 && (
            <button
              className="text-sm text-red-600 dark:text-red-400 hover:text-red-800 dark:hover:text-red-300"
              onClick={() => {
                if (window.confirm('Are you sure you want to clear all favorites?')) {
                  FavoritesManager.clearFavorites();
                  setFavoriteListings([]);
                }
              }}
            >
              Clear All
            </button>
          )}
        </div>

        {/* Content */}
        <div className="mt-6">
          {isLoading ? (
            <div className="flex justify-center items-center p-12">
              <PropLoaderAnimation isLoading={true} text="Loading your favorites..." />
            </div>
          ) : error ? (
            <div className="text-red-500 p-6 text-center">{error}</div>
          ) : favoriteListings.length === 0 ? (
            <div className="text-center py-16">
              <p className="text-gray-500 dark:text-gray-400 mb-4">
                You don't have any favorites yet.
              </p>
              <Link 
                to="/" 
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
              >
                Browse Listings
              </Link>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {favoriteListings.map(listing => (
                <ListingCard
                  key={listing.aircraftId || listing._id}
                  listing={listing}
                  selectedAttributes={[
                    'registration',
                    'year',
                    'totalTime',
                    'engineTime',
                    'propTime',
                    'flightRules'
                  ]}
                  onImageClick={handleImageClick}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      {currentListing && (
        <ImageModal 
          firstImage={currentListing.firstImage}
          imageCount={currentListing.imageCount}
          listingId={currentListing.aircraftId || currentListing._id}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          initialIndex={modalIndex}
        />
      )}
    </div>
  );
}; 