import type { LucideIcon } from 'lucide-react';

// Map of icon names to their dynamic imports
const iconMap: Record<string, () => Promise<{ default: LucideIcon }>> = {
  ArrowLeft: () => import('lucide-react/dist/esm/icons/arrow-left'),
  ArrowRight: () => import('lucide-react/dist/esm/icons/arrow-right'),
  ArrowDownWideNarrow: () => import('lucide-react/dist/esm/icons/arrow-down-wide-narrow'),
  Check: () => import('lucide-react/dist/esm/icons/check'),
  ChevronLeft: () => import('lucide-react/dist/esm/icons/chevron-left'),
  ChevronRight: () => import('lucide-react/dist/esm/icons/chevron-right'),
  CircleDollarSign: () => import('lucide-react/dist/esm/icons/circle-dollar-sign'),
  Cog: () => import('lucide-react/dist/esm/icons/cog'),
  Filter: () => import('lucide-react/dist/esm/icons/filter'),
  Heart: () => import('lucide-react/dist/esm/icons/heart'),
  ListFilter: () => import('lucide-react/dist/esm/icons/list-filter'),
  ListPlus: () => import('lucide-react/dist/esm/icons/list-plus'),
  Moon: () => import('lucide-react/dist/esm/icons/moon'),
  PlaneTakeoff: () => import('lucide-react/dist/esm/icons/plane-takeoff'),
  RotateCcw: () => import('lucide-react/dist/esm/icons/rotate-ccw'),
  Search: () => import('lucide-react/dist/esm/icons/search'),
  Server: () => import('lucide-react/dist/esm/icons/server'),
  Settings: () => import('lucide-react/dist/esm/icons/settings'),
  Settings2: () => import('lucide-react/dist/esm/icons/settings-2'),
  SlidersHorizontal: () => import('lucide-react/dist/esm/icons/sliders-horizontal'),
  Sun: () => import('lucide-react/dist/esm/icons/sun'),
  X: () => import('lucide-react/dist/esm/icons/x'),
  ZoomIn: () => import('lucide-react/dist/esm/icons/zoom-in'),
  ZoomOut: () => import('lucide-react/dist/esm/icons/zoom-out'),
};

// Cache for loaded icons
const iconCache: Record<string, LucideIcon> = {};

// Type for the icon names we support
export type IconName = string & keyof typeof iconMap;

export async function loadIcon(name: IconName): Promise<LucideIcon> {
  // Convert kebab-case to PascalCase for lookup
  const pascalCaseName = name.replace(/-([a-z])/g, g => g[1].toUpperCase())
    .replace(/^([a-z])/, g => g[0].toUpperCase()) as IconName;

  // Return cached icon if available
  if (iconCache[pascalCaseName]) {
    return iconCache[pascalCaseName];
  }

  // Check if the icon exists in our map
  if (!(pascalCaseName in iconMap)) {
    throw new Error(`Icon "${name}" not found in icon map`);
  }

  // Load and cache the icon
  const module = await iconMap[pascalCaseName]();
  iconCache[pascalCaseName] = module.default;
  return module.default;
} 