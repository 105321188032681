import { useRef, useEffect, useState, useCallback } from 'react';
import { Icon } from './Icon';
import { useQueryState, parseAsInteger } from 'nuqs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Zoom, Navigation } from 'swiper/modules';
import type { Swiper as SwiperType } from 'swiper';
import { useListingImages } from '../contexts/ListingImagesContext';
import { motion, AnimatePresence } from 'framer-motion';
import { PropLoaderAnimation } from './PropLoaderAnimation';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';

// Consistent button styling
const BUTTON_STYLES = "p-3 rounded-full bg-black/50 hover:bg-black/90 text-white hover:text-white/90 transition-colors shadow-lg";
const ICON_SIZE = 18;

interface ImageModalProps {
  firstImage?: { th?: string; hq?: string };
  imageCount: number;
  listingId: string;
  isOpen: boolean;
  onClose: () => void;
  initialIndex?: number;
}

// Loader component with pulsing animation
function Loader({ size = 'medium', className = '', text = '' }: { size?: 'small' | 'medium' | 'large', className?: string, text?: string }) {
  return (
    <div className={`flex items-center justify-center ${className}`}>
      <PropLoaderAnimation isLoading={true} text={text} />
    </div>
  );
}

// Navigation button component
function NavButton({
  direction,
  onClick,
  disabled = false
}: {
  direction: 'prev' | 'next';
  onClick: () => void;
  disabled?: boolean;
}) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`absolute ${direction === 'prev' ? 'left-4' : 'right-4'} top-1/2 -translate-y-1/2 z-[100] ${BUTTON_STYLES} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      aria-label={direction === 'prev' ? 'Previous image' : 'Next image'}
    >
      <Icon 
        name={direction === 'prev' ? 'ChevronLeft' : 'ChevronRight'} 
        size={ICON_SIZE}
      />
    </button>
  );
}

// Zoom controls component
function ZoomControls({
  zoomIn,
  zoomOut,
  scale,
  isZoomed
}: {
  zoomIn: () => void;
  zoomOut: () => void;
  scale: number;
  isZoomed: boolean;
}) {
  return (
    <div className="absolute right-4 top-1/2 -translate-y-1/2 flex flex-col gap-2 z-[100]">
      <button
        onClick={zoomIn}
        className={BUTTON_STYLES}
        aria-label="Zoom in"
      >
        <Icon name="ZoomIn" size={ICON_SIZE} />
      </button>
      <button
        onClick={zoomOut}
        className={BUTTON_STYLES}
        aria-label="Zoom out"
      >
        <Icon name="ZoomOut" size={ICON_SIZE} />
      </button>
      <div className="bg-black/50 text-white text-xs px-2 py-1 rounded text-center">
        {Math.round(scale * 100)}%
      </div>
    </div>
  );
}

export function ImageModal({ 
  firstImage,
  imageCount,
  listingId,
  isOpen, 
  onClose, 
  initialIndex = 0
}: ImageModalProps) {
  // Get images from context
  const { images, loading, loadImagesForListing } = useListingImages();
  
  // State for tracking current index during the session
  const [currentIndex, setCurrentIndex] = useState<number>(initialIndex);
  
  // Swiper and zoom state
  const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(null);
  const [scale, setScale] = useState(1);
  const [isZoomed, setIsZoomed] = useState(false);
  const [imageLoadStatus, setImageLoadStatus] = useState<Record<number, boolean>>({});
  const containerRef = useRef<HTMLDivElement>(null);
  
  // Animation sequence state
  const [overlayAnimationComplete, setOverlayAnimationComplete] = useState(false);
  
  // Add a local state to control the modal visibility with AnimatePresence
  const [localIsOpen, setLocalIsOpen] = useState(false);
  
  // Get images for this listing (or use firstImage as fallback)
  const listingImages = images[listingId] || (firstImage ? [firstImage] : []);
  const isLoading = loading[listingId];
  
  // Safe index to ensure it's within bounds
  const safeIndex = Math.max(0, Math.min(currentIndex, (listingImages?.length || 0) - 1));
  
  // Handle the actual close with a delay to allow exit animations to complete
  const handleClose = () => {
    setLocalIsOpen(false);
    // Wait for exit animation to complete before calling the parent onClose
    setTimeout(() => {
      onClose();
    }, 500); // Slightly longer than exit animation duration to ensure it completes
  };
  
  // Sync the local state with the prop, but allow time for exit animations
  useEffect(() => {
    if (isOpen) {
      setLocalIsOpen(true);
    }
  }, [isOpen]);
  
  // Reset animation state when modal closes
  useEffect(() => {
    if (!isOpen) {
      // Reset animation state for next open
      setOverlayAnimationComplete(false);
    }
  }, [isOpen]);
  
  // Load images when modal opens
  useEffect(() => {
    if (isOpen && imageCount > 1) {
      loadImagesForListing(listingId, firstImage);
    }
  }, [isOpen, listingId, imageCount, firstImage, loadImagesForListing]);
  
  // Set current index when modal is opened
  useEffect(() => {
    if (isOpen) {
      setCurrentIndex(initialIndex);
    }
  }, [isOpen, initialIndex]);
  
  // Reset state when modal closes
  useEffect(() => {
    if (!isOpen) {
      setScale(1);
      setIsZoomed(false);
      setImageLoadStatus({});
    }
  }, [isOpen]);
  
  // Zoom handlers
  const handleZoomChange = (swiper: SwiperType, scale: number) => {
    setScale(scale);
    setIsZoomed(scale > 1);
  };
  
  const handleZoomIn = () => {
    if (swiperInstance && swiperInstance.zoom) {
      swiperInstance.zoom.in();
    }
  };
  
  const handleZoomOut = () => {
    if (swiperInstance && swiperInstance.zoom) {
      swiperInstance.zoom.out();
    }
  };
  
  const toggleZoom = () => {
    if (isZoomed) {
      handleZoomOut();
    } else {
      handleZoomIn();
    }
  };
  
  // Navigation handlers
  const handlePrevImage = useCallback(() => {
    if (swiperInstance) {
      swiperInstance.slidePrev();
      if (isZoomed) {
        handleZoomOut();
      }
    }
  }, [swiperInstance, isZoomed]);
  
  const handleNextImage = useCallback(() => {
    if (swiperInstance) {
      swiperInstance.slideNext();
      if (isZoomed) {
        handleZoomOut();
      }
    }
  }, [swiperInstance, isZoomed]);
  
  // Preload image
  const preloadImage = (src: string, idx: number) => {
    if (imageLoadStatus[idx]) return;
    
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImageLoadStatus(prev => ({ ...prev, [idx]: true }));
    };
  };
  
  // Handle swiper events
  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.on('slideChange', (swiper) => {
        setCurrentIndex(swiper.activeIndex);
        setScale(1);
        setIsZoomed(false);
      });
      
      swiperInstance.on('zoomChange', (swiper, scale) => {
        handleZoomChange(swiper, scale);
      });

      // Custom mousewheel behavior to zoom in increments
      const handleWheel = (e: WheelEvent) => {
        if (!swiperInstance || !isOpen) return;
        
        // Prevent default wheel behavior when modal is open
        e.preventDefault();
        
        // Get current zoom level and calculate new zoom
        const currentZoom = scale;
        let newZoom = currentZoom;
        
        if (e.deltaY < 0) {
          // Scroll up = zoom in by 0.15
          newZoom = Math.min(3, currentZoom + 0.15);
        } else {
          // Scroll down = zoom out by 0.15
          newZoom = Math.max(1, currentZoom - 0.15);
        }
        
        // Only update if zoom level changed
        if (newZoom !== currentZoom) {
          if (newZoom <= 1.05) {
            // If zooming out to near 1, just reset zoom completely
            swiperInstance.zoom.out();
          } else if (newZoom > currentZoom) {
            // When zooming in, use the specific zoom level
            swiperInstance.zoom.in(newZoom);
          } else {
            // When zooming out, use the specific zoom level
            // Need to handle this specially as Swiper doesn't have a direct method
            const zoom = swiperInstance.zoom;
            if (zoom && zoom.scale > newZoom) {
              // Then zoom to specific level if still needed
              if (newZoom > 1.05) {
                setTimeout(() => {
                  swiperInstance.zoom.in(newZoom);
                }, 10);
              }
            }
          }
        }
      };
      
      // Attach the wheel event to the container
      const container = containerRef.current;
      if (container) {
        container.addEventListener('wheel', handleWheel, { passive: false });
      }
      
      return () => {
        if (container) {
          container.removeEventListener('wheel', handleWheel);
        }
        
        swiperInstance.off('slideChange');
        swiperInstance.off('zoomChange');
      };
    }
  }, [swiperInstance, isOpen, scale]);
  
  // Ensure swiper is on the correct initial slide
  useEffect(() => {
    if (swiperInstance && swiperInstance.activeIndex !== safeIndex) {
      swiperInstance.slideTo(safeIndex, 0);
      setScale(1);
      setIsZoomed(false);
    }
  }, [safeIndex, swiperInstance]);
  
  // Preload images when modal opens
  useEffect(() => {
    if (!isOpen || listingImages.length === 0) return;
    
    // First, preload adjacent images
    const prevIndex = (safeIndex - 1 + listingImages.length) % listingImages.length;
    const nextIndex = (safeIndex + 1) % listingImages.length;
    
    if (listingImages[prevIndex]?.hq) {
      preloadImage(listingImages[prevIndex].hq!, prevIndex);
    }
    
    if (listingImages[nextIndex]?.hq) {
      preloadImage(listingImages[nextIndex].hq!, nextIndex);
    }
    
    // Then preload the rest of the images
    const timeoutId = setTimeout(() => {
      listingImages.forEach((image, idx) => {
        if (idx !== safeIndex && idx !== prevIndex && idx !== nextIndex && image.hq) {
          preloadImage(image.hq!, idx);
        }
      });
    }, 500);
    
    return () => clearTimeout(timeoutId);
  }, [isOpen, safeIndex, listingImages]);
  
  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isOpen) return;
      
      switch (e.key) {
        case 'Escape':
          if (isZoomed) {
            handleZoomOut();
          } else {
            handleClose(); // Use our new close function
          }
          break;
        case 'ArrowLeft':
          if (!isZoomed) {
            handlePrevImage();
            e.preventDefault();
          }
          break;
        case 'ArrowRight':
          if (!isZoomed) {
            handleNextImage();
            e.preventDefault();
          }
          break;
        case '+':
        case '=':
          handleZoomIn();
          e.preventDefault();
          break;
        case '-':
          handleZoomOut();
          e.preventDefault();
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, handleClose, isZoomed, handlePrevImage, handleNextImage]);
  
  return (
    <AnimatePresence mode="wait">
      {localIsOpen && (
        <motion.div 
          className="fixed inset-0 z-[70] bg-black/90 flex items-center justify-center"
          ref={containerRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ 
            duration: 0.3, 
            ease: "easeOut",
            exit: { duration: 0.2 }
          }}
          onAnimationComplete={() => {
            setOverlayAnimationComplete(true);
          }}
        >
          {/* Close button */}
          <motion.button 
            className="absolute top-4 right-4 text-white p-3 rounded-full bg-black/50 hover:bg-black/90 transition-colors z-[100]"
            onClick={handleClose}
            aria-label="Close"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ 
              duration: 0.2, 
              ease: "easeOut",
              delay: 0.2,
              exit: { duration: 0.1, delay: 0 }
            }}
          >
            <Icon name="X" size={ICON_SIZE} />
          </motion.button>
          
          {/* Zoom button - positioned in center bottom */}
          <motion.button
            className="absolute bottom-4 left-1/2 -translate-x-1/2 z-[100] flex items-center justify-center gap-1 px-4 py-2 rounded-full bg-black/50 hover:bg-black/90 text-white"
            onClick={toggleZoom}
            aria-label={isZoomed ? "Zoom Out" : "Zoom In"}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ 
              duration: 0.2, 
              ease: "easeOut",
              delay: 0.25,
              exit: { duration: 0.1, delay: 0 }
            }}
          >
            <Icon name={isZoomed ? "ZoomOut" : "ZoomIn"} size={ICON_SIZE} />
            <span className="text-sm">{isZoomed ? "Zoom Out" : "Zoom In"}</span>
          </motion.button>
          
          {/* Main content */}
          <motion.div 
            className="w-full h-full flex flex-col items-center justify-center relative"
            initial={{ opacity: 0 }}
            animate={{ opacity: overlayAnimationComplete ? 1 : 0 }}
            exit={{ opacity: 0 }}
            transition={{ 
              duration: 0.3, 
              ease: "easeOut",
              exit: { duration: 0.15, delay: 0 }
            }}
          >
            {/* Gallery loading indicator */}
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-black/70 z-[90] backdrop-blur-sm transition-all duration-300 pointer-events-none">
                <div className="flex flex-col items-center justify-center gap-3 p-8 rounded-xl bg-black/50 shadow-2xl">
                  <Loader size="large" text="Loading gallery..." />
                </div>
              </div>
            )}
            
            {/* Image swiper */}
            {listingImages.length > 0 && (
              <Swiper
                modules={[Keyboard, Zoom, Navigation]}
                slidesPerView={1}
                initialSlide={safeIndex}
                onSwiper={setSwiperInstance}
                zoom={{
                  maxRatio: 3,
                  minRatio: 1,
                  toggle: true,
                  containerClass: 'swiper-zoom-container',
                }}
                keyboard={{ enabled: true }}
                className="w-full h-full"
                navigation={false} // We'll use our custom navigation buttons
              >
                {listingImages.map((image, index) => (
                  <SwiperSlide key={`slide-${index}`} className="flex items-center justify-center">
                    <div 
                      className="swiper-zoom-container flex items-center justify-center w-full h-full"
                      onClick={(e) => {
                        // Only handle background clicks
                        if (e.target === e.currentTarget) {
                          e.stopPropagation();
                          if (isZoomed) {
                            handleZoomOut();
                          } else {
                            onClose();
                          }
                        }
                      }}
                      style={{ 
                        width: '100%', 
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: 'transform 0.3s ease-out'
                      }}
                    >
                      {/* Individual image loading state */}
                      {!imageLoadStatus[index] && image.hq && (
                        <div className="absolute inset-0 flex items-center justify-center pointer-events-none z-10">
                          <Loader text={`Loading image ${index + 1}...`} />
                        </div>
                      )}
                      
                      <img
                        src={image.hq}
                        alt={`Image ${index + 1}`}
                        className={`max-w-full max-h-full object-contain select-none cursor-grab active:cursor-grabbing transition-opacity duration-300 ${!imageLoadStatus[index] ? 'opacity-0' : 'opacity-100'}`}
                        onLoad={() => {
                          setImageLoadStatus(prev => ({ ...prev, [index]: true }));
                        }}
                        style={{
                          maxWidth: '90%',
                          maxHeight: '90%',
                          transition: 'transform 0.3s ease-out'
                        }}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </motion.div>
          
          {/* Navigation buttons */}
          {listingImages.length > 1 && (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: overlayAnimationComplete && !isLoading ? 1 : 0 }}
                exit={{ opacity: 0 }}
                transition={{ 
                  duration: 0.2, 
                  delay: 0.1,
                  exit: { duration: 0.1, delay: 0 }
                }}
              >
                <NavButton
                  direction="prev"
                  onClick={handlePrevImage}
                  disabled={isZoomed}
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: overlayAnimationComplete && !isLoading ? 1 : 0 }}
                exit={{ opacity: 0 }}
                transition={{ 
                  duration: 0.2, 
                  delay: 0.1,
                  exit: { duration: 0.1, delay: 0 }
                }}
              >
                <NavButton
                  direction="next"
                  onClick={handleNextImage}
                  disabled={isZoomed}
                />
              </motion.div>
            </>
          )}

          {/* Image counter */}
          <motion.div 
            className="absolute bottom-4 right-4 bg-black/50 text-white px-3 py-1 rounded text-sm z-[100]"
            initial={{ opacity: 0 }}
            animate={{ opacity: overlayAnimationComplete ? 1 : 0 }}
            exit={{ opacity: 0 }}
            transition={{ 
              duration: 0.2, 
              delay: 0.25,
              exit: { duration: 0.1, delay: 0 }
            }}
          >
            {safeIndex + 1}/{imageCount}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
} 