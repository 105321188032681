import type { MongoQuery } from './types.js';

export interface RangeConfig {
  min: number;
  max: number;
  step: number;
  includeBeforeMin: boolean;  // Include values below min
  includeAboveMax: boolean;   // Include values above max
}

export const RANGE_CONFIGS: { [key: string]: RangeConfig } = {
  priceRange: {
    min: 0,
    max: 1000000,
    step: 10000,
    includeBeforeMin: false,  // Don't include negative prices
    includeAboveMax: true,    // Include prices above $1M
  },
  yearRange: {
    min: 1920,
    max: new Date().getFullYear() + 1,
    step: 1,
    includeBeforeMin: true,   // Include years before 1920
    includeAboveMax: true,   // Don't include years above max
  },
  engineTimeRange: {
    min: 0,
    max: 5000,
    step: 100,
    includeBeforeMin: false,  // Don't include negative engine time
    includeAboveMax: true,    // Include engine time above 5000 hours
  },
  usefulLoadRange: {
    min: 0,
    max: 10000,
    step: 100,
    includeBeforeMin: false,  // Don't include negative useful load
    includeAboveMax: true,    // Include useful load above 10,000 lbs
  }
};

export function getRangeDefaults(key: string): [number, number] {
  const config = RANGE_CONFIGS[key];
  return [config.min, config.max];
}

export function buildMongoRangeFilter(params: {
  key: string;
  field: string;
  min: number;
  max: number;
  values: number[] | null;
  includeBeforeMin: boolean;
  includeAboveMax: boolean;
}): any {
  // Get the config for this range
  const config = RANGE_CONFIGS[params.key];
  
  // Determine if we're using default min/max values
  const isDefaultMin = params.min === config.min;
  const isDefaultMax = params.max === config.max;
  
  // Check if we need to include values outside the range
  // For price, we check if zero price is allowed separately
  if (params.key === 'yearRange') {
    const orConditions = [];
    
    // Create the basic year query
    const yearQuery: any = {};
    
    // Add min condition if needed
    if (!isDefaultMin) {
      yearQuery.$gte = params.min;
    }
    
    // Add max condition if needed
    if (!isDefaultMax) {
      yearQuery.$lte = params.max;
    }
    
    // If we have an empty filter (both default min and default max), 
    // return a condition that matches everything
    if (isDefaultMin && isDefaultMax) {
      return {}; // Match everything
    }
    
    // If we're using default min (no specific lower bound)
    if (isDefaultMin) {
      // Include the specific query
      orConditions.push({ [params.field]: yearQuery });
      
      // Include nulls and non-existent values
      orConditions.push({ [params.field]: null });
      orConditions.push({ [params.field]: { $exists: false } });
      
      return { $or: orConditions };
    }
    
    // Otherwise, just use a direct query with the specific range
    return { [params.field]: yearQuery };
  }
  
  // For other range types, use a standard range filter
  return {};
} 