/**
 * Animation Configuration
 * 
 * This file contains shared animation settings to ensure consistent motion
 * throughout the application.
 */

export const animations = {
  // Card animations
  card: {
    appear: {
      duration: 0.35,
      ease: "easeOut"
    },
    disappear: {
      duration: 0.2,
      ease: "easeInOut"
    },
    staggerDelay: 0.03, // Reduced delay for more cohesive group animation
    initialY: 15,      // Reduced distance for subtler animation
  },
  
  // Modal animations
  modal: {
    enter: {
      duration: 0.3,
      ease: "easeOut"
    },
    exit: {
      duration: 0.2,
      ease: "easeInOut"
    }
  },
  
  // Filter animations
  filter: {
    duration: 0.2,
    ease: "easeInOut"
  },
  
  // Loading transition
  loading: {
    duration: 0.3,
    staggerDelay: 0.05
  }
}; 