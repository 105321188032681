import { ProductListing } from '../types/api';

const FAVORITES_KEY = 'product-favorites';

/**
 * Hook for managing product favorites in local storage
 */
export const FavoritesManager = {
  /**
   * Get all favorite listings from local storage
   */
  getFavorites: (): string[] => {
    try {
      const favoritesJson = localStorage.getItem(FAVORITES_KEY);
      return favoritesJson ? JSON.parse(favoritesJson) : [];
    } catch (error) {
      console.error('Error getting favorites from local storage:', error);
      return [];
    }
  },

  /**
   * Check if a listing is favorited
   */
  isFavorite: (listingId: string): boolean => {
    const favorites = FavoritesManager.getFavorites();
    return favorites.includes(listingId);
  },

  /**
   * Toggle a listing as favorite
   */
  toggleFavorite: (listingId: string): boolean => {
    try {
      const favorites = FavoritesManager.getFavorites();
      const isFavorited = favorites.includes(listingId);
      
      let newFavorites;
      if (isFavorited) {
        // Remove from favorites
        newFavorites = favorites.filter(id => id !== listingId);
      } else {
        // Add to favorites
        newFavorites = [...favorites, listingId];
      }
      
      localStorage.setItem(FAVORITES_KEY, JSON.stringify(newFavorites));
      return !isFavorited; // Return new state
    } catch (error) {
      console.error('Error toggling favorite in local storage:', error);
      return FavoritesManager.isFavorite(listingId);
    }
  },

  /**
   * Get the total count of favorites
   */
  getFavoritesCount: (): number => {
    return FavoritesManager.getFavorites().length;
  },

  /**
   * Clear all favorites
   */
  clearFavorites: (): void => {
    localStorage.removeItem(FAVORITES_KEY);
  }
}; 