import { useState, useEffect, useRef } from 'react';

const STORAGE_KEY = 'columnCount';

// Breakpoint constants (in pixels)
const BREAKPOINTS = {
  SM: 640,    // Mobile to tablet
  MD: 1024,   // Tablet to desktop
  LG: 1280,   // Desktop to large desktop
} as const;

export type ColumnCount = 1 | 2 | 3 | 4;

export function useColumnCount() {
  // Initialize with a default value of 3
  const [columnCount, setColumnCountState] = useState<ColumnCount>(3);
  const lastWidthRef = useRef(window.innerWidth);
  const isInitializedRef = useRef(false);

  // Load from localStorage on mount
  useEffect(() => {
    if (!isInitializedRef.current) {
      const stored = localStorage.getItem(STORAGE_KEY);
      if (stored) {
        const parsed = parseInt(stored, 10);
        if (parsed >= 1 && parsed <= 4) {
          setColumnCountState(parsed as ColumnCount);
        }
      }
      
      // Initialize based on screen size
      const width = window.innerWidth;
      if (width < BREAKPOINTS.SM) {
        setColumnCountState(1);
      } else if (width >= BREAKPOINTS.SM && width < BREAKPOINTS.MD) {
        setColumnCountState(2);
      } else if (width >= BREAKPOINTS.MD && width < BREAKPOINTS.LG) {
        setColumnCountState(3);
      } else {
        setColumnCountState(4);
      }
      
      isInitializedRef.current = true;
    }
  }, []);

  // Handle responsive resizing
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const isGrowing = width > lastWidthRef.current;
      lastWidthRef.current = width;

      // Only adjust column count based on screen size constraints
      setColumnCountState((currentCount) => {
        let newColumnCount: ColumnCount = currentCount;

        if (width < BREAKPOINTS.SM) {
          newColumnCount = 1;
        } else if (width >= BREAKPOINTS.SM && width < BREAKPOINTS.MD) {
          // On tablets, max is 2 columns
          newColumnCount = Math.min(currentCount, 2) as ColumnCount;
          // If growing and currently at 1, suggest 2 columns
          if (isGrowing && currentCount === 1) {
            newColumnCount = 2;
          }
        } else if (width >= BREAKPOINTS.MD && width < BREAKPOINTS.LG) {
          // On desktop, max is 3 columns
          newColumnCount = Math.min(currentCount, 3) as ColumnCount;
          // If growing from tablet size (2 columns), suggest 3
          if (isGrowing && currentCount <= 2) {
            newColumnCount = 3;
          }
        } else {
          // On large screens (>= 1280px), allow 4 columns
          // If growing from desktop size, suggest 4
          if (isGrowing && currentCount < 4) {
            newColumnCount = 4;
          }
        }

        // Only update if the column count needs to change
        if (newColumnCount !== currentCount) {
          localStorage.setItem(STORAGE_KEY, newColumnCount.toString());
          return newColumnCount;
        }
        return currentCount;
      });
    };

    // Use ResizeObserver for more reliable resize detection
    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        handleResize();
      });
    });

    // Observe the document body to ensure it works regardless of drawer state
    resizeObserver.observe(document.body);

    // Also add the standard resize listener as a fallback
    window.addEventListener('resize', handleResize);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Update localStorage when columnCount changes
  const setColumnCount = (count: ColumnCount) => {
    setColumnCountState(count);
    localStorage.setItem(STORAGE_KEY, count.toString());
  };

  return [columnCount, setColumnCount] as const;
} 