export interface ProductCategory {
  _id: string;
  name: string;
  slug: string;
  attributes: {
    name: string;
    label: string;
    type: string;
    group: string;
    isRequired: boolean;
    options?: string[];
  }[];
  equipmentCategories: {
    name: string;
    label: string;
  }[];
}

export interface ApiResponse<T> {
  data: T | null;
  error: string | null;
}

export interface Image {
  url: string;
  alt?: string;
  th?: string;
  hq?: string;
  order?: number;
}

export interface ScrapingSource {
  source: string;
  url: string;
  title: string;
  price: number;
  lastUpdatedDate: Date;
  postedDate: Date;
  soldDate?: Date;
  description?: string;
  generalSpecs?: string;
  avionicsEquipment?: string;
  additionalEquipment?: string;
  airframe?: string;
  engine?: string;
  props?: string;
  modsConversions?: string;
  interiorExterior?: string;
  remarks?: string;
}

export interface EquipmentItem {
  installed: boolean;
  details: string;
  models?: string[];
}

export interface ProductListing {
  _id: string;
  aircraftId?: string;
  title: string;
  price: number;
  description: string;
  createdDate: Date;
  updatedDate: Date;
  latestListingUpdateDate: Date;
  latestListingUpdateSource: string;
  status: 'active' | 'sold' | 'removed';
  
  // Image fields
  firstImage?: { th?: string; hq?: string };
  imageCount: number;
  
  // Seller information
  seller?: {
    name?: string;
    company?: string;
    phone?: string;
    email?: string;
    location?: string;
    city?: string;
    state?: string;
    country?: string;
    website?: string;
  };
  
  // New schema fields
  listingAttributes: {
    condition?: string;
    saleStatus?: string;
    logBookStatus?: string;
    interiorNotes?: string;
    interiorScore?: number;
    interiorYear?: number;
    exteriorNotes?: string;
    paintScore?: number;
    paintYear?: number;
    inspectionStatus?: string;
    inspectionExpiry?: Date;
    registration?: string;
    serialNumber?: string;
    owner?: string;
    registrationAircaft?: string;
    registrationIssueDate?: Date;
    registrationExpirationDate?: Date;
    registrationType?: string;
    registrationCity?: string;
    registrationState?: string;
    registrationCountry?: string;
    registrationZip?: string;
  };
  
  modelInformation: {
    year?: number;
    make?: string;
    model?: string;
    modelFamily?: string;
    marketingName?: string;
    category?: string;
    intendedUse?: string[];
  };
  
  airframeEngine: {
    engineType?: string;
    engineCount?: number;
    engineMake?: string;
    engineModel?: string;
    enginePower?: string;
    enginePowerHp?: number;
    propellerModel?: string;
    stolModifications?: string;
    engine1Time?: string;
    engine1TimeHours?: number;
    engine2Time?: string;
    engine2TimeHours?: number;
    prop1Time?: string;
    prop1TimeHours?: number;
    prop2Time?: string;
    prop2TimeHours?: number;
    airframeTime?: string;
    airframeTimeHours?: number;
  };
  
  specifications: {
    emptyWeightLbs?: number;
    grossWeightLbs?: number;
    fuelCapacityGals?: number;
    fuelCapacityGal?: number;
    usefulLoadLbs?: number;
    flightRules?: string;
    maxSpeedKts?: number;
    cruiseSpeedKts?: number;
    stallSpeedKts?: number;
    range?: number;
    seats?: number;
  };
  
  avionics: Record<string, EquipmentItem>;
  additionalEquipment: Record<string, EquipmentItem>;
  
  images: Image[];
  scrapingSources: ScrapingSource[];
  
  // Keep these for compatibility during transition
  attributes?: Record<string, any>;
  equipment?: Record<string, { installed: boolean; details: string[] }>;
  contact?: string;
  url?: string;
  source?: string;
  postedDate?: string;
  listingUpdatedDate?: string;
}

export interface Facets {
  makes: string[];
  model: string[];
  years: {
    min: number;
    max: number;
  };
  priceRange: {
    min: number;
    max: number;
  };
  sources: string[];
  flightRules: string[];
  equipment: Record<string, string[]>;
  modelCounts: Record<string, number>;
}

export interface ListingsResponse {
  listings: ProductListing[];
  total: number;
  totalPages: number;
  availableFilters: {
    makes: Option[];
    model: Array<Option & { make: string }>;
    equipment: Array<{ _id: { category: string; value: string }; count: number }>;
    prices: Array<{ _id: null; min: number; max: number }>;
  };
  sort: string;
  debug?: {
    query: Record<string, any>;
    rangeConfigs: Record<string, any>;
    params: Record<string, any>;
    total: number;
    sortOrder: Record<string, any>;
    currentSort: string;
    rangeStats: {
      price: {
        min: number | null;
        max: number | null;
        totalCount: number;
        belowMinCount: number;
        aboveMaxCount: number;
        nullCount: number;
      };
      year: {
        min: number | null;
        max: number | null;
        totalCount: number;
        belowMinCount: number;
        aboveMaxCount: number;
        nullCount: number;
      };
    };
  };
}

export const equipmentCategories = {
  gps: 'GPS',
  radio: 'Communications Radio',
  autopilot: 'Autopilot',
  audioPanel: 'Audio Panel',
  weather: 'Weather System',
  traffic: 'Traffic System',
  adsbOut: 'ADS-B Out',
  adsbIn: 'ADS-B In',
  pfd: 'Primary Flight Display (PFD)',
  mfd: 'Multi-Function Display (MFD)',
  emsEis: 'Engine Monitoring System'
} as const;

export type EquipmentCategory = keyof typeof equipmentCategories;

export type CardMetric = 
  | 'registration'
  | 'year'
  | 'totalTime'
  | 'engineTime'
  | 'propTime'
  | 'flightRules'
  | 'make'
  | 'model'
  | 'serialNumber'
  | 'usefulLoad'
  | `equipment.${EquipmentCategory}`;

export interface MetricOption {
  key: CardMetric;
  label: string;
  group: 'basic' | 'equipment';
}

export interface BaseOption {
  value: string;
  label: string;
}

export interface SelectOption {
  value: string;
  label: string;
  count?: number;
  make?: string;
}

export interface Option extends BaseOption {
  count: number;
  zeroCount: number;
  make?: string;
}

export interface ListingsParams {
  search?: string;
  make?: string[];
  model?: string[];
  equipment?: Record<string, string[]>;
  showZeroPrice?: boolean;
  priceRange?: [number, number];
  yearRange?: [number, number];
  totalTimeRange?: [number, number];
  engineTimeRange?: [number, number];
  page?: number;
  limit?: number;
  sort?: string;
}

export interface Filters {
  make: string[];
  model: string[];
  yearRange?: {
    min: string;
    max: string;
  };
  minPrice?: string;
  maxPrice?: string;
  source?: string;
  equipment: Record<EquipmentCategory, string[]>;
  flightRules?: string;
  search?: string;
  showZeroPrice?: boolean;
}

export interface AvailableFilters {
  makes: SelectOption[];
  models: Array<SelectOption & { make: string }>;
  years: { min: number; max: number };
  equipment: Record<string, SelectOption[]>;
}

// Add stricter types for filter values
interface FilterValue<T> {
  value: T;
  isValid: boolean;
  error?: string;
}

type FilterState = {
  [K in keyof Filters]: FilterValue<Filters[K]>;
};

export interface ListingsApiResponse {
  listings: ProductListing[];
  total: number;
  totalPages: number;
  availableFilters: {
    makes: Array<Option>;
    model: Array<Option & { make: string }>;
    equipment: Record<string, Option[]>;
    prices: Array<{ min: number; max: number }>;
  };
}// For our internal API response
export interface ApiOption {
  value: string;
  label: string;
  count: number;
  zeroCount: number;
  make?: string;
}

interface ApiMake {
  value: string;
  label: string;
  count: number;
}

interface ApiModel {
  value: string;
  label: string;
  count: number;
  make: string;
}

interface ApiFilters {
  makes: ApiMake[];
  model: ApiModel[];
  // ... other filter properties
}

