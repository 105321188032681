import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { Icon } from './Icon';
import { api } from '../api/client';
import type { ProductListing, EquipmentCategory } from '../types/api';
import { equipmentCategories } from '../types/api';
import { ImageModal } from './ImageModal';
import { FavoriteButton } from './FavoriteButton';
import { motion, AnimatePresence } from 'framer-motion';
import { useListingImages } from '../contexts/ListingImagesContext';
import { PropLoaderAnimation } from './PropLoaderAnimation';

// Equipment labels for human-readable display
// Note: We import equipmentCategories for type consistency, but define our own more descriptive labels
// rather than using the short abbreviations from the original mapping (e.g., "AP" → "Autopilot")
const equipmentLabels: Record<string, string> = {
  // Avionics (using keys from equipmentCategories)
  autopilot: 'Autopilot',
  gps: 'GPS',
  radio: 'Communications Radio',
  audioPanel: 'Audio Panel',
  weather: 'Weather System',
  traffic: 'Traffic System',
  adsbOut: 'ADS-B Out',
  adsbIn: 'ADS-B In',
  pfd: 'Primary Flight Display (PFD)',
  mfd: 'Multi-Function Display (MFD)',
  emsEis: 'Engine Monitoring System',
  efis: 'Electronic Flight Instrument System',
  cdu: 'Central Display Unit',
  nav: 'Navigation System',
  efb: 'Electronic Flight Bag',
  vhfCom: 'VHF Communications',
  satCom: 'Satellite Communications',
  acars: 'ACARS',
  radarAlt: 'Radar Altimeter',
  egpws: 'EGPWS',
  cvr: 'Cockpit Voice Recorder',
  elt: 'ELT',
  fms: 'Flight Management System',
  irs: 'Instrument Landing System',
  adc: 'ADC',
  apu: 'APU',
  apuGen: 'APU Generator',
  apuProp: 'APU Propeller',
  apuFuel: 'APU Fuel',
  apuFuelPump: 'APU Fuel Pump',
  dme: 'DME',
  hsi: 'HSI',
  adf: 'ADF',
  hfCom: 'HF Communications',
  selcal: 'Selective Calling',
  xpndr: 'Transponder',
  tcAS: 'TC/AS',
  fdr: 'FDR',
  lightingSensor: 'Lighting Sensor',

  // Additional equipment not in the shared type file
  fuelSystem: 'Fuel System',
  landingGear: 'Landing Gear',
  lights: 'Lighting',
  propeller: 'Propeller',
  seats: 'Seats',
  deIce: 'De-Ice System',
  aircondition: 'Air Conditioning',
  engine: 'Engine Modifications',
  
  // LED-related equipment
  ledLights: 'LED Lights',
  ledTailBeacon: 'LED Tail Beacon',
  ledNavLights: 'LED Navigation Lights',
  ledStrobeLights: 'LED Strobe Lights',
  ledLandingLights: 'LED Landing Lights',
  ledTaxiLights: 'LED Taxi Lights',
};

const getEquipmentLabel = (key: string): string => {
  // Return predefined label if it exists, otherwise return the key as-is
  return equipmentLabels[key] || key;
};

const getRelativeTimeString = (dateString: string) => {
  try {
    const date = new Date(dateString);
    const now = new Date(); // Use actual current time
    
    const msPerDay = 1000 * 60 * 60 * 24;
    const msPerMonth = msPerDay * 30.44; // Average month length
    const msPerYear = msPerDay * 365.25; // Account for leap years
    
    // Check if the date is in the future
    if (date > now) {
      const diff = date.getTime() - now.getTime();
      const days = Math.ceil(diff / msPerDay);
      
      if (days === 1) {
        return "(1 day from now)";
      } else if (days < 30) {
        return `(${days} days from now)`;
      } else {
        const months = Math.floor(diff / msPerMonth);
        return `(${months} month${months !== 1 ? 's' : ''} from now)`;
      }
    }
    
    // Past date calculation
    const diff = now.getTime() - date.getTime();
    const days = Math.floor(diff / msPerDay);
    const months = Math.floor(diff / msPerMonth);
    const years = Math.floor(diff / msPerYear);
    
    // For recent dates, just show days
    if (days === 0) {
      return "(today)";
    } else if (days === 1) {
      return "(yesterday)";
    } else if (days < 30) {
      return `(${days} days ago)`;
    } else if (months < 12) {
      return `(${months} month${months !== 1 ? 's' : ''} ago)`;
    } else {
      return `(${years} year${years !== 1 ? 's' : ''} ago)`;
    }
  } catch (e) {
    console.error("Error calculating relative time:", e);
    return "";
  }
};

// Use the same field labels as in AircraftSearch
const fieldLabels: Record<string, string> = {
  registration: 'Tail #',
  year: 'Year',
  totalTime: 'Total Time',
  engineTime: 'Engine Time',
  propTime: 'Prop Time',
  flightRules: 'Flight Rules',
  make: 'Make',
  model: 'Model',
  serialNumber: 'Serial #',
  usefulLoad: 'Useful Load',
  marketingName: 'Marketing Name',
  location: 'Location'
};

// MODAL MODE PROPS
interface ListingDetailProps {
  isModal?: boolean;
  onModalClose?: () => void;
  listingId?: string;
}

export const ListingDetail = ({ 
  isModal = false, 
  onModalClose,
  listingId
}: ListingDetailProps) => {
  const { id: urlId } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const [listing, setListing] = useState<ProductListing | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [modalIndex, setModalIndex] = useState<number>(0);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const { loadImagesForListing } = useListingImages();
  
  // Use the ID from props if in modal mode, otherwise from URL params
  const id = listingId || urlId;

  useEffect(() => {
    const fetchListing = async () => {
      if (!id) {
        // No ID provided, redirect to root
        navigate('/', { replace: true });
        return;
      }
      
      setIsLoading(true);
      setError(null);

      try {
        const { data, error } = await api.listings.getById(id);
        
        if (error) {
          setError(error);
          // Redirect to root on error
          navigate('/', { replace: true });
        } else if (data) {
          setListing(data);
          
          // Start loading images in background if needed
          if (data.imageCount > 1) {
            loadImagesForListing(id, data.firstImage);
          }
        } else {
          // No data returned, redirect to root
          navigate('/', { replace: true });
        }
      } catch (err) {
        console.error('Error fetching listing details:', err);
        setError(err instanceof Error ? err.message : 'Failed to load listing details');
        // Redirect to root on error
        navigate('/', { replace: true });
      } finally {
        setIsLoading(false);
      }
    };

    fetchListing();
  }, [id, navigate, loadImagesForListing]);

  // Load images when component mounts
  useEffect(() => {
    if (id && listing?.firstImage) {
      loadImagesForListing(id, listing.firstImage);
    }
  }, [id, listing, loadImagesForListing]);

  const handleBackClick = () => {
    if (isModal && onModalClose) {
      // Close the modal if in modal mode
      onModalClose();
    } else {
      // Standard navigation behavior for direct routes
      // Check if we have model/make info for search suggestion
      if (listing?.modelInformation?.make && listing?.modelInformation?.modelFamily) {
        // Navigate to filtered search by make/model
        navigate(`/?makes=${encodeURIComponent(listing.modelInformation.make)}&modelFamilies=${encodeURIComponent(listing.modelInformation.modelFamily)}`);
      } else if (window.history.state?.idx === 0 || !window.history.length) {
        // No history or at the start of history, navigate to root with current search params
        // This prevents "stranded" states with no back navigation
        navigate({
          pathname: '/',
          search: location.search.replace(/detailId=[^&]+(&|$)/, '') // Remove detailId from search params
        }, { replace: true }); // Use replace to avoid adding to history
      } else {
        // Otherwise go back
        navigate(-1);
      }
    }
  };

  // Loading state
  if (isLoading) {
    return (
      <div className={isModal ? "" : "min-h-screen bg-white dark:bg-neutral-900 pt-20"}>
        <div className="mx-auto max-w-[1600px] px-4 sm:px-6 lg:px-8 bg-white dark:bg-neutral-900">
          {!isModal && (
            <div className="fixed top-0 left-0 right-0 py-4 sm:py-6 flex items-center justify-between z-[60] bg-white dark:bg-neutral-900 border-b border-gray-200 dark:border-gray-800">
              <div className="mx-auto max-w-[1600px] px-4 sm:px-6 lg:px-8 w-full">
                <div className="flex items-center">
                  <h1 className="text-3xl font-bold tracking-tight text-blue-900 dark:text-white flex items-center">
                    <span className="font-extrabold tracking-tight ml-1">All</span>
                    <span className="font-light ml-[5px] tracking-normal">the</span>
                    <span className="font-extrabold tracking-tight ml-1">Planes</span>
                  </h1>
                </div>
              </div>
            </div>
          )}
          {/* Back button */}
          <div className="flex items-center justify-between h-16 mb-6 py-4">
            <button
              onClick={handleBackClick}
              className="flex items-center justify-between gap-2 p-2 mr-2 text-sm font-medium rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-700 dark:text-gray-300"
              aria-label="Back"
            >
              <Icon name="ArrowLeft" size={24} /> 
              {isModal && "Back to Search"}
            </button>
          </div>
          
          <div className="flex justify-center items-center h-[400px]">
            <PropLoaderAnimation isLoading={true} text="Loading listing details..." />
          </div>
        </div>
      </div>
    );
  }

  // For hard-coded error states (should be rare since we're redirecting)
  if (error || !listing) {
    // Return minimal div while redirect happens (from useEffect)
    return <div></div>;
  }

  return (
    <div className={isModal ? "" : "min-h-screen bg-white dark:bg-neutral-900 pt-[100px]"}>
      <div className="mx-auto max-w-[1600px] px-4 sm:px-6 lg:px-8 bg-white dark:bg-neutral-900">
        {!isModal && (
          <div className="fixed top-0 left-0 right-0 py-4 sm:py-6 flex items-center justify-between z-[60] bg-white dark:bg-neutral-900 border-b border-gray-200 dark:border-gray-800">
            <div className="mx-auto max-w-[1600px] px-4 sm:px-6 lg:px-8 w-full">
              <div className="flex items-center">
                <h1 className="text-3xl font-bold tracking-tight text-blue-900 dark:text-white flex items-center">
                  <span className="font-extrabold tracking-tight ml-1">All</span>
                  <span className="font-light ml-[5px] tracking-normal">the</span>
                  <span className="font-extrabold tracking-tight ml-1">Planes</span>
                </h1>
              </div>
            </div>
          </div>
        )}
        {/* Back button */}
        <div className="flex items-center justify-between h-16 py-4">
          <button
            onClick={handleBackClick}
            className="flex items-center justify-between gap-2 p-2 mr-2 text-sm font-medium rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-700 dark:text-gray-300"
            aria-label={isModal ? "Close" : "Back"}
          >
            <Icon name="ArrowLeft" size={24} /> 
            {!isModal && listing.modelInformation?.make && listing.modelInformation?.modelFamily && (
              <Link 
                to={`/?makes=${encodeURIComponent(listing.modelInformation.make)}&modelFamilies=${encodeURIComponent(listing.modelInformation.modelFamily)}`}
                className="text-sm"
              >
                Search {listing.modelInformation.make} {listing.modelInformation.modelFamily}
              </Link>
            )} {isModal && "Back to Search"}
          </button>
        </div>

        {/* Main Content Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Column - Images */}
          <div className="space-y-2">
            <div className="aspect-[4/3] w-full bg-gray-100 dark:bg-neutral-800 rounded-lg overflow-hidden">
              {listing.images && listing.images.length > 0 ? (
                <img
                  src={listing.images[0].hq}
                  alt={listing.title}
                  className="w-full h-full object-cover cursor-pointer"
                  onClick={() => {
                    setModalIndex(0);
                    setIsImageModalOpen(true);
                  }}
                />
              ) : (
                <div className="flex items-center justify-center h-full text-gray-400">
                  No image available
                </div>
              )}
            </div>

            {/* Thumbnail Grid */}
            {listing.images && listing.images.length > 1 && (
              <div className="grid grid-cols-4 gap-2">
                {listing.images.slice(1).map((image, index) => (
                  <motion.div
                    key={index + 1}
                    className="aspect-[4/3] rounded-lg overflow-hidden cursor-pointer"
                    onClick={() => {
                      setModalIndex(index + 1);
                      setIsImageModalOpen(true);
                    }}
                    whileHover={{
                      scale: [null, 1.05],
                      transition: {
                        duration: 0.3,
                        times: [0, 0.6],
                        ease: ["easeInOut", "easeOut"],
                      },
                    }}
                    transition={{
                      duration: 0.2,
                      ease: "easeOut",
                    }}
                  >
                    <img
                      src={image.th}
                      alt={`${listing.title} - Image ${index + 2}`}
                      className="w-full h-full object-cover hover:opacity-80 transition-opacity"
                    />
                  </motion.div>
                ))}
              </div>
            )}
          </div>

          {/* Right Column - Details */}
          <div className="space-y-6 justify-between">
            <span className="flex justify-between items-center">
              <h1 className="sm:text-3xl text-xl font-bold text-gray-900 dark:text-white">
                {listing.modelInformation?.year} {listing.modelInformation?.make} {listing.modelInformation?.model}
              </h1>
              <FavoriteButton 
                listingId={listing.aircraftId || listing._id}
                size="md"
                showCount={true}
              />
            </span>
            {/* Price Section */}
            {listing.price && listing.price > 0 && (
              <span className="sm:text-xl text-lg font-bold text-green-600 dark:text-green-500 dark:text-white">
                ${listing.price.toLocaleString()}
              </span>
            )}

            {/* Basic Info */}
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                {/* Model Information */}
                {listing.modelInformation?.year && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Year</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.modelInformation.year}
                    </dd>
                  </div>
                )}
                {listing.modelInformation?.make && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Make</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.modelInformation.make}
                    </dd>
                  </div>
                )}
                {listing.modelInformation?.modelFamily && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Model Family</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.modelInformation.modelFamily}
                    </dd>
                  </div>
                )}
                {listing.modelInformation?.model && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Model</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.modelInformation.model}
                    </dd>
                  </div>
                )}
                {listing.modelInformation?.marketingName && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Marketing Name</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.modelInformation.marketingName}
                    </dd>
                  </div>
                )}
                {listing.modelInformation?.category && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Category</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.modelInformation.category}
                    </dd>
                  </div>
                )}
                {listing.modelInformation?.intendedUse && listing.modelInformation.intendedUse.length > 0 && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Intended Use</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.modelInformation.intendedUse.join(', ')}
                    </dd>
                  </div>
                )}
                
                {/* Listing Attributes */}
                {listing.listingAttributes?.registration && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Tail #</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.listingAttributes.registration}
                    </dd>
                  </div>
                )}
                {listing.listingAttributes?.serialNumber && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Serial #</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.listingAttributes.serialNumber}
                    </dd>
                  </div>
                )}
                
                {/* Airframe/Engine */}
                {listing.airframeEngine?.airframeTime && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Total Time</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.airframeEngine.airframeTimeHours ? `${listing.airframeEngine.airframeTimeHours.toLocaleString()} ` : ''}({listing.airframeEngine.airframeTime})
                    </dd>
                  </div>
                )}
                {listing.airframeEngine?.engine1Time && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Engine Time</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.airframeEngine.engine1TimeHours ? `${listing.airframeEngine.engine1TimeHours.toLocaleString()} ` : ''}({listing.airframeEngine.engine1Time})
                    </dd>
                  </div>
                )}
                {listing.airframeEngine?.prop1Time && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Prop Time</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.airframeEngine.prop1TimeHours ? `${listing.airframeEngine.prop1TimeHours.toLocaleString()} ` : ''}({listing.airframeEngine.prop1Time})
                    </dd>
                  </div>
                )}
                
                {/* Specifications */}
                {listing.specifications?.flightRules && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Flight Rules</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.specifications.flightRules}
                    </dd>
                  </div>
                )}
                {listing.specifications?.usefulLoadLbs && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Useful Load</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.specifications.usefulLoadLbs.toLocaleString()} lbs
                    </dd>
                  </div>
                )}
                {listing.specifications?.maxSpeedKts && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Max Speed</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.specifications.maxSpeedKts} kts
                    </dd>
                  </div>
                )}
                {listing.specifications?.cruiseSpeedKts && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Cruise Speed</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.specifications.cruiseSpeedKts} kts
                    </dd>
                  </div>
                )}
                {listing.specifications?.stallSpeedKts && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Stall Speed</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.specifications.stallSpeedKts} kts
                    </dd>
                  </div>
                )}
                {listing.specifications?.fuelCapacityGal && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Fuel Capacity</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.specifications.fuelCapacityGal} gal
                    </dd>
                  </div>
                )}
                {listing.specifications?.range && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Range</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.specifications.range} nm
                    </dd>
                  </div>
                )}
                {listing.specifications?.seats && (
                  <div className="space-y-1">
                    <dt className="text-sm text-gray-500 dark:text-gray-400">Seats</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-white">
                      {listing.specifications.seats}
                    </dd>
                  </div>
                )}
              </div>
            </div>

            {/* Additional Listing Attributes section */}
            {(listing.listingAttributes?.condition || 
              listing.listingAttributes?.saleStatus || 
              listing.listingAttributes?.logBookStatus || 
              listing.listingAttributes?.inspectionStatus || 
              listing.listingAttributes?.inspectionExpiry) && (
              <div className="space-y-4">
                <h2 className="text-lg font-medium text-gray-900 dark:text-white">
                  Listing Status
                </h2>
                <div className="grid grid-cols-2 gap-4">
                  {listing.listingAttributes?.condition && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Condition</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.listingAttributes.condition}
                      </dd>
                    </div>
                  )}
                  {listing.listingAttributes?.saleStatus && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Sale Status</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.listingAttributes.saleStatus}
                      </dd>
                    </div>
                  )}
                  {listing.listingAttributes?.logBookStatus && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Logbook Status</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.listingAttributes.logBookStatus}
                      </dd>
                    </div>
                  )}
                  {listing.listingAttributes?.inspectionStatus && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Inspection Status</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.listingAttributes.inspectionStatus}
                      </dd>
                    </div>
                  )}
                  {listing.listingAttributes?.inspectionExpiry && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Inspection Expiry</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {new Date(listing.listingAttributes.inspectionExpiry).toLocaleDateString()}
                      </dd>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Interior/Exterior Details */}
            {(listing.listingAttributes?.interiorNotes || 
              listing.listingAttributes?.interiorScore || 
              listing.listingAttributes?.interiorYear || 
              listing.listingAttributes?.exteriorNotes || 
              listing.listingAttributes?.paintScore || 
              listing.listingAttributes?.paintYear) && (
              <div className="space-y-4">
                <h2 className="text-lg font-medium text-gray-900 dark:text-white">
                  Interior & Exterior
                </h2>
                <div className="grid grid-cols-2 gap-4">
                  {listing.listingAttributes?.interiorScore && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Interior Rating</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.listingAttributes.interiorScore}/10
                      </dd>
                    </div>
                  )}
                  {listing.listingAttributes?.interiorYear && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Interior Year</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.listingAttributes.interiorYear}
                      </dd>
                    </div>
                  )}
                  {listing.listingAttributes?.paintScore && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Paint Rating</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.listingAttributes.paintScore}/10
                      </dd>
                    </div>
                  )}
                  {listing.listingAttributes?.paintYear && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Paint Year</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.listingAttributes.paintYear}
                      </dd>
                    </div>
                  )}
                </div>
                {listing.listingAttributes?.interiorNotes && (
                  <div className="mt-4">
                    <dt className="text-sm text-gray-500 dark:text-gray-400 mb-1">Interior Notes</dt>
                    <dd className="text-sm text-gray-700 dark:text-gray-300 whitespace-pre-line">
                      {listing.listingAttributes.interiorNotes}
                    </dd>
                  </div>
                )}
                {listing.listingAttributes?.exteriorNotes && (
                  <div className="mt-4">
                    <dt className="text-sm text-gray-500 dark:text-gray-400 mb-1">Exterior Notes</dt>
                    <dd className="text-sm text-gray-700 dark:text-gray-300 whitespace-pre-line">
                      {listing.listingAttributes.exteriorNotes}
                    </dd>
                  </div>
                )}
              </div>
            )}

            {/* Registration Details */}
            {(listing.listingAttributes?.registrationAircaft || 
              listing.listingAttributes?.registrationType || 
              listing.listingAttributes?.registrationIssueDate || 
              listing.listingAttributes?.registrationExpirationDate || 
              listing.listingAttributes?.registrationCity || 
              listing.listingAttributes?.registrationState || 
              listing.listingAttributes?.registrationCountry) && (
              <div className="space-y-4">
                <h2 className="text-lg font-medium text-gray-900 dark:text-white">
                  Registration Details
                </h2>
                <div className="grid grid-cols-2 gap-4">
                  {listing.listingAttributes?.registrationAircaft && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Registration</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.listingAttributes.registrationAircaft}
                      </dd>
                    </div>
                  )}
                  {listing.listingAttributes?.registrationType && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Registration Type</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.listingAttributes.registrationType}
                      </dd>
                    </div>
                  )}
                  {listing.listingAttributes?.registrationIssueDate && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Issue Date</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {new Date(listing.listingAttributes.registrationIssueDate).toLocaleDateString()}
                      </dd>
                    </div>
                  )}
                  {listing.listingAttributes?.registrationExpirationDate && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Expiration Date</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {new Date(listing.listingAttributes.registrationExpirationDate).toLocaleDateString()}
                      </dd>
                    </div>
                  )}
                  {listing.listingAttributes?.owner && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Owner</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.listingAttributes.owner}
                      </dd>
                    </div>
                  )}
                  {(listing.listingAttributes?.registrationCity || 
                    listing.listingAttributes?.registrationState || 
                    listing.listingAttributes?.registrationCountry || 
                    listing.listingAttributes?.registrationZip) && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Location</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {[
                          listing.listingAttributes.registrationCity,
                          listing.listingAttributes.registrationState,
                          listing.listingAttributes.registrationZip,
                          listing.listingAttributes.registrationCountry
                        ].filter(Boolean).join(', ')}
                      </dd>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Engine Details */}
            {(listing.airframeEngine?.engineType || 
              listing.airframeEngine?.engineCount || 
              listing.airframeEngine?.engineMake || 
              listing.airframeEngine?.engineModel || 
              listing.airframeEngine?.enginePower || 
              listing.airframeEngine?.enginePowerHp || 
              listing.airframeEngine?.propellerModel) && (
              <div className="space-y-4">
                <h2 className="text-lg font-medium text-gray-900 dark:text-white">
                  Engine & Propeller
                </h2>
                <div className="grid grid-cols-2 gap-4">
                  {listing.airframeEngine?.engineType && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Engine Type</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.airframeEngine.engineType}
                      </dd>
                    </div>
                  )}
                  {listing.airframeEngine?.engineCount && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Engine Count</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.airframeEngine.engineCount}
                      </dd>
                    </div>
                  )}
                  {listing.airframeEngine?.engineMake && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Engine Make</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.airframeEngine.engineMake}
                      </dd>
                    </div>
                  )}
                  {listing.airframeEngine?.engineModel && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Engine Model</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.airframeEngine.engineModel}
                      </dd>
                    </div>
                  )}
                  {(listing.airframeEngine?.enginePower || listing.airframeEngine?.enginePowerHp) && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Engine Power</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.airframeEngine.enginePowerHp ? `${listing.airframeEngine.enginePowerHp} HP` : listing.airframeEngine.enginePower}
                      </dd>
                    </div>
                  )}
                  {listing.airframeEngine?.propellerModel && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Propeller Model</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.airframeEngine.propellerModel}
                      </dd>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Avionics Section */}
            {listing.avionics && Object.keys(listing.avionics).length > 0 && (
              <div className="space-y-4">
                <h2 className="text-lg font-medium text-gray-900 dark:text-white">
                  Avionics
                </h2>
                <div className="grid grid-cols-2 gap-4">
                  {Object.entries(listing.avionics).map(([key, data]) => {
                    // Skip if data is null or not an object
                    if (!data || typeof data !== 'object') return null;
                    
                    return (
                    <div key={key} className="space-y-2">
                      <dt className="text-sm text-gray-500 dark:text-gray-400 font-medium">
                        {getEquipmentLabel(key)}
                      </dt>
                      
                      {/* Show models with tooltip for details */}
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        <div className="relative group">
                          <div className="flex items-center">
                            {/* Only show info icon if there are details */}
                            {data.details && (
                              <span className="mr-1.5 text-blue-800 dark:text-blue-400 cursor-help group-hover:text-blue-900 dark:group-hover:text-blue-300">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clipRule="evenodd" />
                                </svg>
                              </span>
                            )}
                            {/* Show the first model in the models array if available */}
                            <span>
                              {data.models?.length > 0 
                                ? data.models[0]
                                : (data.installed ? 'Installed' : 'Not installed')}
                            </span>
                            
                            
                          </div>
                          
                          {/* Tooltip for details */}
                          {data.details && (
                            <div className="absolute z-10 invisible group-hover:visible bg-black bg-opacity-90 text-white p-2.5 rounded text-xs mt-1 max-w-xs whitespace-normal bottom-full left-0 shadow-lg transform -translate-y-1">
                              {data.details}
                            </div>
                          )}
                        </div>
                      </dd>
                    </div>
                    );
                  })}
                </div>
              </div>
            )}

            {/* Additional Equipment Section */}
            {listing.additionalEquipment && Object.keys(listing.additionalEquipment).length > 0 && (
              <div className="space-y-4">
                <h2 className="text-lg font-medium text-gray-900 dark:text-white">
                  Additional Equipment
                </h2>
                <div className="grid grid-cols-2 gap-4">
                  {Object.entries(listing.additionalEquipment).map(([key, data]) => {
                    // Skip if data is null or not an object
                    if (!data || typeof data !== 'object') return null;
                    
                    return (
                    <div key={key} className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">
                        {getEquipmentLabel(key)}
                      </dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        <div className="relative group">
                          <div className="flex items-center">
                            {/* Only show info icon if there are details AND models */}
                            {data.details && data.models?.length > 0 && (
                              <span className="mr-1.5 text-blue-800 dark:text-blue-400 cursor-help group-hover:text-blue-900 dark:group-hover:text-blue-300">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clipRule="evenodd" />
                                </svg>
                              </span>
                            )}
                            <span>
                              {data.models?.length > 0 ? data.models[0] :
                              data.details || 
                              (data.installed ? 'Installed' : (
                                <span className="text-gray-300 dark:text-gray-500 font-normal">Not Specified</span>
                              ))}
                            </span>
                          </div>
                          
                          {/* Tooltip for details - only show when there are models */}
                          {data.details && data.models?.length > 0 && (
                            <div className="absolute z-10 invisible group-hover:visible bg-black bg-opacity-90 text-white p-2.5 rounded text-xs mt-1 max-w-xs whitespace-normal bottom-full left-0 shadow-lg transform -translate-y-1">
                              {data.details}
                            </div>
                          )}
                        </div>
                      </dd>
                    </div>
                    );
                  })}
                </div>
              </div>
            )}

            {/* Seller Information */}
            {listing.seller && Object.keys(listing.seller).some(key => listing.seller[key]) && (
              <div className="space-y-6">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                  Contact Information
                </h3>
                <div className="space-y-4">
                  {listing.seller.name && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Name</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.seller.name}
                      </dd>
                    </div>
                  )}
                  {listing.seller.company && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Company</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.seller.company}
                      </dd>
                    </div>
                  )}
                  {listing.seller.phone && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Phone</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {listing.seller.phone}
                      </dd>
                    </div>
                  )}
                  {listing.seller.email && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Email</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        <a href={`mailto:${listing.seller.email}`} className="text-blue-800 dark:text-blue-400 hover:underline">
                          {listing.seller.email}
                        </a>
                      </dd>
                    </div>
                  )}
                  {listing.seller.location && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Location</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        {typeof listing.seller.location === 'string'
                          ? listing.seller.location
                          : [
                            listing.seller.city,
                            listing.seller.state,
                            listing.seller.country
                          ].filter(Boolean).join(', ')}
                      </dd>
                    </div>
                  )}
                  {listing.seller.website && (
                    <div className="space-y-1">
                      <dt className="text-sm text-gray-500 dark:text-gray-400">Website</dt>
                      <dd className="text-sm font-medium text-gray-900 dark:text-white">
                        <a href={listing.seller.website} target="_blank" rel="noopener noreferrer" className="text-blue-800 dark:text-blue-400 hover:underline">
                          {listing.seller.website.replace(/^https?:\/\//, '')}
                        </a>
                      </dd>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Source Detail Sections */}
            {listing.scrapingSources && listing.scrapingSources.length > 0 && (
              <div className="space-y-6 mt-8 pt-6 border-t border-gray-200 dark:border-gray-700">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Source Details
                </h2>
                
                {listing.scrapingSources.map((source, index) => (
                  <div key={`source-detail-${index}`} className="p-4 bg-gray-100 dark:bg-neutral-800 rounded-lg space-y-4">
                    <div className="flex items-center justify-between">
                      <h3 className="text-lg font-medium text-gray-900 dark:text-white flex items-center">
                        {source.url && (
                          <a 
                            href={source.url} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="hover:text-blue-800 dark:hover:text-blue-300"
                          >
                            {source.source}
                          </a>
                        )}
                        {source.lastUpdatedDate && (
                          <span className="ml-2 text-xs text-gray-500 dark:text-gray-400">
                            {new Date(String(source.lastUpdatedDate)).toLocaleDateString()}
                            {' '}
                            {getRelativeTimeString(String(source.lastUpdatedDate))}
                          </span>
                        )}
                      </h3>
                      {source.price && (
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                          Price: ${source.price.toLocaleString()}
                        </div>
                      )}
                    </div>
                    
                    {/* Description */}
                    {source.description && (
                      <div className="space-y-2">
                        <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                          Description
                        </h4>
                        <p className="text-sm text-gray-600 dark:text-gray-300 whitespace-pre-line">
                          {source.description}
                        </p>
                      </div>
                    )}
                    
                    {/* General Specs */}
                    {source.generalSpecs && (
                      <div className="space-y-2">
                        <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                          General Specifications
                        </h4>
                        <p className="text-sm text-gray-600 dark:text-gray-300 whitespace-pre-line">
                          {source.generalSpecs}
                        </p>
                      </div>
                    )}
                    
                    {/* Avionics Equipment */}
                    {source.avionicsEquipment && (
                      <div className="space-y-2">
                        <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                          Avionics Equipment
                        </h4>
                        <p className="text-sm text-gray-600 dark:text-gray-300 whitespace-pre-line">
                          {source.avionicsEquipment}
                        </p>
                      </div>
                    )}
                    
                    {/* Additional Equipment */}
                    {source.additionalEquipment && (
                      <div className="space-y-2">
                        <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                          Additional Equipment
                        </h4>
                        <p className="text-sm text-gray-600 dark:text-gray-300 whitespace-pre-line">
                          {source.additionalEquipment}
                        </p>
                      </div>
                    )}
                    
                    {/* Airframe */}
                    {source.airframe && (
                      <div className="space-y-2">
                        <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                          Airframe
                        </h4>
                        <p className="text-sm text-gray-600 dark:text-gray-300 whitespace-pre-line">
                          {source.airframe}
                        </p>
                      </div>
                    )}
                    
                    {/* Engine */}
                    {source.engine && (
                      <div className="space-y-2">
                        <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                          Engine
                        </h4>
                        <p className="text-sm text-gray-600 dark:text-gray-300 whitespace-pre-line">
                          {source.engine}
                        </p>
                      </div>
                    )}
                    
                    {/* Props */}
                    {source.props && (
                      <div className="space-y-2">
                        <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                          Propeller
                        </h4>
                        <p className="text-sm text-gray-600 dark:text-gray-300 whitespace-pre-line">
                          {source.props}
                        </p>
                      </div>
                    )}
                    
                    {/* Mods/Conversions */}
                    {source.modsConversions && (
                      <div className="space-y-2">
                        <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                          Modifications & Conversions
                        </h4>
                        <p className="text-sm text-gray-600 dark:text-gray-300 whitespace-pre-line">
                          {source.modsConversions}
                        </p>
                      </div>
                    )}
                    
                    {/* Interior/Exterior */}
                    {source.interiorExterior && (
                      <div className="space-y-2">
                        <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                          Interior & Exterior
                        </h4>
                        <p className="text-sm text-gray-600 dark:text-gray-300 whitespace-pre-line">
                          {source.interiorExterior}
                        </p>
                      </div>
                    )}
                    
                    {/* Remarks */}
                    {source.remarks && (
                      <div className="space-y-2">
                        <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                          Remarks
                        </h4>
                        <p className="text-sm text-gray-600 dark:text-gray-300 whitespace-pre-line">
                          {source.remarks}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            {/* Source and Date */}
            <div className="pt-4 border-t border-gray-200 dark:border-gray-700 flex justify-between text-sm text-gray-500 dark:text-gray-400">
              <div className="flex flex-col gap-1">
                {/* Show all available sources from scrapingSources */}
                {listing.scrapingSources && listing.scrapingSources.length > 0 ? (
                  <div className="flex flex-col gap-1">
                    <span className="text-xs text-gray-500 dark:text-gray-400">Sources:</span>
                    {listing.scrapingSources.map((source, index) => (
                      source.url && (
                        <a 
                          key={`source-${index}`}
                          href={source.url} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="hover:text-blue-800 dark:hover:text-blue-300"
                        >
                          {source.source}
                        </a>
                      )
                    ))}
                  </div>
                ) : (
                  <span className="text-xs text-gray-500 dark:text-gray-400">No source information available</span>
                )}
              </div>
              
              {/* Always show the most recent update date */}
              {listing.scrapingSources && listing.scrapingSources.length > 0 && listing.scrapingSources[0].lastUpdatedDate && (
                <time dateTime={String(listing.scrapingSources[0].lastUpdatedDate)}>
                  Updated: {
                    (() => {
                      try {
                        return new Date(String(listing.scrapingSources[0].lastUpdatedDate)).toLocaleDateString();
                      } catch (e) {
                        return 'Date unavailable';
                      }
                    })()
                  }{' '}
                  <span className="text-gray-400 dark:text-gray-500">
                    {(() => {
                      try {
                        return getRelativeTimeString(String(listing.scrapingSources[0].lastUpdatedDate));
                      } catch (e) {
                        return '';
                      }
                    })()}
                  </span>
                </time>
              )}
            </div>                
          </div>
        </div>

        {/* Image Modal */}
        {isImageModalOpen && listing && (
          <ImageModal
            firstImage={listing.firstImage}
            imageCount={listing.imageCount}
            listingId={listing.aircraftId || listing._id}
            initialIndex={modalIndex}
            onClose={() => setIsImageModalOpen(false)}
            isOpen={isImageModalOpen}
          />
        )}
      </div>
    </div>
  );
}; 