import { useState, useEffect } from 'react';
import { Icon } from './Icon';
import { FavoritesManager } from '../utils/favorites';
import { cn } from '../lib/utils';
import { motion, AnimatePresence } from 'framer-motion';

interface FavoriteButtonProps {
  listingId?: string;
  className?: string;
  onClick?: (isFavorited: boolean) => void;
  showCount?: boolean;
  count?: number;
  alwaysFilled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  showShadow?: boolean;
  redOutline?: boolean;
  allowNavigation?: boolean;
}

export const FavoriteButton = ({ 
  listingId, 
  className = '',
  onClick,
  showCount = false,
  count = 0,
  alwaysFilled = false,
  size = 'md',
  showShadow = true,
  redOutline = false,
  allowNavigation = false
}: FavoriteButtonProps) => {
  const [isFavorited, setIsFavorited] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  
  // Sizes based on the size prop
  const sizes = {
    sm: { container: 'h-8 w-8', heart: 20, blurHeart: 24 },
    md: { container: 'h-10 w-10', heart: 24, blurHeart: 30 },
    lg: { container: 'h-10 w-10', heart: 32, blurHeart: 38 }
  };
  
  const { container, heart: heartSize, blurHeart: blurHeartSize } = sizes[size];

  // Check if listing is favorited when component mounts or when favorites change
  useEffect(() => {
    if (listingId) {
      setIsFavorited(FavoritesManager.isFavorite(listingId));
    }
    
    // Add listener for favorites changed event 
    const handleFavoritesChange = () => {
      if (listingId) {
        setIsFavorited(FavoritesManager.isFavorite(listingId));
      }
    };
    
    window.addEventListener('favoritesChanged', handleFavoritesChange);
    
    return () => {
      window.removeEventListener('favoritesChanged', handleFavoritesChange);
    };
  }, [listingId]);

  const handleToggleFavorite = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent card click event from firing
    
    // Only prevent default navigation if allowNavigation is false
    if (!allowNavigation) {
      e.preventDefault(); // Prevent any navigation
    }
    
    // Trigger animation
    setIsAnimating(true);
    
    if (listingId) {
      const newFavoritedState = FavoritesManager.toggleFavorite(listingId);
      setIsFavorited(newFavoritedState);
      
      // Dispatch a custom event to notify that favorites have changed
      window.dispatchEvent(new CustomEvent('favoritesChanged'));
      
      if (onClick) {
        onClick(newFavoritedState);
      }
    } else if (onClick) {
      // If no listingId, just call the onClick handler
      onClick(!isFavorited);
    }
    
    // Reset animation state after animation completes
    setTimeout(() => {
      setIsAnimating(false);
    }, 600);
  };

  // Determine if the heart should be filled based on different conditions
  const shouldFillHeart = alwaysFilled || isFavorited;

  return (
    <motion.button
      className={cn('relative flex items-center justify-center transition-all', container, className)}
      onClick={handleToggleFavorite}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      aria-label={shouldFillHeart && !alwaysFilled ? 'Remove from favorites' : 'Add to favorites'}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", stiffness: 400, damping: 17 }}
    >
      <div className="relative flex items-center justify-center">
        {/* Outer blurred stroke - creates a soft glow effect */}
        {showShadow && (
          <Icon name="Heart" size={blurHeartSize} className="absolute text-black/70 blur-[3px]" />
        )}
        
        {/* Main heart with crisp edges and animations */}
        <motion.div
          animate={isAnimating ? {
            scale: [1, 1.2, 1],
            rotate: [0, -10, 10, -10, 0],
          } : {}}
          transition={{
            duration: 0.6,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 1],
          }}
        >
          <Icon name="Heart" size={heartSize} strokeWidth={1.3} className={cn(
            'relative z-10',
            shouldFillHeart 
              ? `fill-red-500 ${redOutline ? 'text-red-500' : 'text-white'}` 
              : isHovered
                ? `${redOutline ? 'text-red-500' : 'text-white'} fill-transparent` 
                : `${redOutline ? 'text-red-500' : 'text-white'} fill-transparent`
          )} />
        </motion.div>
        
        {/* Count indicator with animation */}
        {showCount && (
          <AnimatePresence>
            {count > 0 && (
              <motion.span 
                className="absolute inset-0 z-20 flex -translate-y-0.5 items-center justify-center text-xs font-bold text-white"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5 }}
                transition={{ type: "spring", stiffness: 500, damping: 30 }}
              >
                {count > 99 ? '99+' : count}
              </motion.span>
            )}
          </AnimatePresence>
        )}
      </div>
    </motion.button>
  );
};

// Keep export for backward compatibility during transition
export const FavoriteHeartButton = FavoriteButton; 