import { 
  parseAsString as parseString,
  parseAsArrayOf as parseArray,
  parseAsBoolean as parseBoolean,
  createParser
} from 'nuqs';
import { RANGE_CONFIGS, getRangeDefaults } from '../shared/range-config.js';
import type { SortOption } from '../types/queryState';

// Core listing parameters
export const parseMakes = parseArray(parseString).withDefault([]);
export const parseModelFamilies = parseArray(parseString).withDefault([]);
export const parseModels = parseArray(parseString).withDefault([]);
export const parseEquipment = parseString.withDefault('');
export const parseShowZeroPrice = parseBoolean.withDefault(false);
export const parseShowSold = parseBoolean.withDefault(false);
export const parseEngineTimeRange = parseString.withDefault('');
export const parseUsefulLoadRange = parseString.withDefault('');
export const parseSort = parseString.withDefault('relevance');
export const parseCategory = parseString.withDefault('');
export const parseIntendedUse = parseArray(parseString).withDefault([]);
export const parseCondition = parseString.withDefault('');
export const parseSaleStatus = parseString.withDefault('');

// Price range parser with validation
export const parsePriceRange = createParser({
  parse: (value: string | null): [number, number] => {
    if (!value) return getRangeDefaults('priceRange');
    const parts = value.split(',').map(Number);
    if (parts.length === 2 && !parts.some(isNaN) && 
        parts[0] >= RANGE_CONFIGS.priceRange.min && parts[1] >= parts[0]) {
      return [parts[0], parts[1]];
    }
    return getRangeDefaults('priceRange');
  },
  serialize: (value: [number, number]) => value.join(',')
}).withDefault(getRangeDefaults('priceRange'));

// Year range parser with validation
export const parseYearRange = createParser({
  parse: (value: string | null): [number, number] => {
    if (!value) return getRangeDefaults('yearRange');
    const parts = value.split(',').map(Number);
    if (parts.length === 2 && !parts.some(isNaN) && 
        parts[0] >= RANGE_CONFIGS.yearRange.min && parts[1] >= parts[0] && 
        parts[1] <= RANGE_CONFIGS.yearRange.max) {
      return [parts[0], parts[1]];
    }
    return getRangeDefaults('yearRange');
  },
  serialize: (value: [number, number]) => value.join(',')
}).withDefault(getRangeDefaults('yearRange'));

// View state parsers
export const parseView = createParser({
  parse: (value: string | null): 'grid' | 'list' => {
    return value === 'list' ? 'list' : 'grid';
  },
  serialize: (value: 'grid' | 'list') => value
}).withDefault('grid');

export const parseColumnCount = createParser({
  parse: (value: string | null): 1 | 2 | 3 | 4 => {
    const parsed = parseInt(value || '3', 10);
    return (parsed >= 1 && parsed <= 4 ? parsed : 3) as 1 | 2 | 3 | 4;
  },
  serialize: (value: 1 | 2 | 3 | 4) => value.toString()
}).withDefault(3);

// Pagination parsers
export const parsePage = createParser({
  parse: (value: string | null): number => {
    const parsed = parseInt(value || '1', 10);
    return parsed >= 1 ? parsed : 1;
  },
  serialize: (value: number) => value.toString()
}).withDefault(1);

export const parseItemsPerPage = createParser({
  parse: (value: string | null): number => {
    const parsed = parseInt(value || '20', 10);
    return parsed >= 1 && parsed <= 100 ? parsed : 20;
  },
  serialize: (value: number) => value.toString()
}).withDefault(20);

// Image modal parsers
export const parseImageIndex = createParser({
  parse: (value: string | null): number => {
    const parsed = parseInt(value || '0', 10);
    return parsed >= 0 ? parsed : 0;
  },
  serialize: (value: number) => value.toString()
}).withDefault(0);

// Search parser
export const parseSearch = parseString.withDefault('');

export function validateSort(value: string): SortOption {
  const validSorts: SortOption[] = [
    'relevance',
    'price-asc',
    'price-desc',
    'year-asc',
    'year-desc',
    'date-listed',
    'updated-desc',
    'updated-asc'
  ];
  
  if (validSorts.includes(value as SortOption)) {
    return value as SortOption;
  }
  
  return 'relevance';
}

export function validateColumnCount(value: string): 1 | 2 | 3 | 4 {
  const count = parseInt(value, 10);
  if (count >= 1 && count <= 4) {
    return count as 1 | 2 | 3 | 4;
  }
  return 1;
}