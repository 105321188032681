'use client'

import { PropLoaderAnimation } from './PropLoaderAnimation';

export function LoadingIndicator() {
  return (
    <div className="flex items-center justify-center p-4">
      <PropLoaderAnimation isLoading={true} text="Loading..." />
    </div>
  );
} 