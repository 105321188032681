import { useState, useEffect } from 'react';
import { Icon } from './Icon';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Keyboard } from 'swiper/modules';
import type { Swiper as SwiperType } from 'swiper';
import type { Image } from '../types/api';
import { useListingImages } from '../contexts/ListingImagesContext';

// Import Swiper styles
import 'swiper/css';

interface CardImageSliderProps {
  firstImage?: { th?: string; hq?: string };
  imageCount: number;
  currentIndex: number;
  onIndexChange: (index: number) => void;
  onImageClick: (index: number) => void;
  listingId: string;
  aspectRatio?: 'square' | 'video' | '4:3';
}

export function CardImageSlider({
  firstImage,
  imageCount,
  currentIndex,
  onIndexChange,
  onImageClick,
  listingId,
  aspectRatio = 'square'
}: CardImageSliderProps) {
  const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(null);
  const { images, loading } = useListingImages();
  
  // Add validation at the start of component
  if (!firstImage || imageCount === 0) {
    return (
      <div className={`relative overflow-hidden ${
        {
          square: 'aspect-square',
          video: 'aspect-video',
          '4:3': 'aspect-[4/3]'
        }[aspectRatio]
      } bg-gray-100 flex items-center justify-center`}>
        <span className="text-gray-400">No images available</span>
      </div>
    );
  }

  // Sync swiper index with currentIndex prop
  useEffect(() => {
    if (swiperInstance && swiperInstance.activeIndex !== currentIndex) {
      swiperInstance.slideTo(currentIndex, 0);
    }
  }, [currentIndex, swiperInstance]);

  const aspectRatioClass = {
    square: 'aspect-square',
    video: 'aspect-video',
    '4:3': 'aspect-[4/3]'
  }[aspectRatio];
  
  // Get images for this listing from context, or use firstImage as fallback
  const listingImages = images[listingId] || (firstImage ? [firstImage] : []);
  const isLoading = loading[listingId];

  return (
    <div 
      className={`relative overflow-hidden ${aspectRatioClass} cursor-pointer group`}
    >
      <Swiper
        modules={[Navigation, Pagination, Keyboard]}
        slidesPerView={1}
        initialSlide={currentIndex}
        onSwiper={setSwiperInstance}
        onSlideChange={(swiper) => {
          if (swiper.activeIndex !== currentIndex) {
            onIndexChange(swiper.activeIndex);
          }
        }}
        className="w-full h-full"
      >
        {listingImages.map((image, index) => (
          <SwiperSlide key={`card-slide-${index}`} onClick={() => onImageClick(index)}>
            <div className="w-full h-full flex items-center justify-center">
              <img
                src={image.th}
                alt={`Image ${index + 1}`}
                className="w-full h-full object-cover select-none"
                draggable={false}
              />
            </div>
          </SwiperSlide>
        ))}

        {/* Show placeholder slides for unloaded images */}
        {isLoading && listingImages.length < imageCount && Array.from({ length: imageCount - listingImages.length }).map((_, index) => (
          <SwiperSlide key={`card-slide-${listingImages.length + index}`} onClick={() => onImageClick(listingImages.length + index)}>
            <div className="w-full h-full flex items-center justify-center bg-gray-100">
              <span className="text-gray-400">Loading image {listingImages.length + index + 1}...</span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {imageCount > 1 && (
        <>
          <button
            onClick={(e) => {
              e.stopPropagation();
              swiperInstance?.slidePrev();
            }}
            className="absolute left-2 top-1/2 -translate-y-1/2 p-1.5 rounded-full bg-black/75 text-white md:opacity-0 opacity-100 md:group-hover:opacity-100 transition-opacity z-10"
            aria-label="Previous image"
          >
            <Icon name="ChevronLeft" size={24} className="text-white" />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              swiperInstance?.slideNext();
            }}
            className="absolute right-2 top-1/2 -translate-y-1/2 p-1.5 rounded-full bg-black/75 text-white md:opacity-0 opacity-100 md:group-hover:opacity-100 transition-opacity z-10"
            aria-label="Next image"
          >
            <Icon name="ChevronRight" size={24} className="text-white" />
          </button>

          <div className="absolute bottom-2 right-2 bg-black/50 text-white text-xs px-2 py-0.5 rounded-full md:opacity-0 opacity-100 md:group-hover:opacity-100 transition-opacity z-10">
            {currentIndex + 1}/{imageCount}
          </div>
        </>
      )}
    </div>
  );
} 