import { createContext, useContext, useState, useCallback } from 'react';
import { api } from '../api/client';

// Simple type for images
type ImageData = Array<{ th?: string; hq?: string }>;

interface ListingImagesContextType {
  // Simple map of listing ID to its images
  images: Record<string, ImageData>;
  
  // Simple map tracking which listings are currently loading
  loading: Record<string, boolean>;
  
  // Load images for a single listing
  loadImagesForListing: (listingId: string, firstImage?: { th?: string; hq?: string }) => void;
  
  // Load images for multiple listings at once
  loadImagesForListings: (listingIds: string[]) => void;
}

const ListingImagesContext = createContext<ListingImagesContextType | null>(null);

export function ListingImagesProvider({ children }: { children: React.ReactNode }) {
  // Simple state to track loaded images and loading status
  const [images, setImages] = useState<Record<string, ImageData>>({});
  const [loading, setLoading] = useState<Record<string, boolean>>({});

  // Load images for a single listing
  const loadImagesForListing = useCallback((listingId: string, firstImage?: { th?: string; hq?: string }) => {
    // If we already have images for this listing or it's already loading, do nothing
    if (images[listingId] || loading[listingId]) return;

    // Mark the listing as loading
    setLoading(prev => ({ ...prev, [listingId]: true }));

    // If there's a firstImage, use it while we load the rest
    if (firstImage && !images[listingId]) {
      setImages(prev => ({ ...prev, [listingId]: [firstImage] }));
    }

    // Load the images
    api.listings.getImages(listingId)
      .then(({ data, error }) => {
        if (error) {
          console.error(`Error loading images for listing ${listingId}:`, error);
        } else if (data) {
          setImages(prev => ({ ...prev, [listingId]: data.images }));
        }
      })
      .catch(err => {
        console.error(`Error loading images for listing ${listingId}:`, err);
      })
      .finally(() => {
        setLoading(prev => ({ ...prev, [listingId]: false }));
      });
  }, [images, loading]);

  // Load images for multiple listings at once
  const loadImagesForListings = useCallback((listingIds: string[]) => {
    // Filter out listings that are already loaded or loading
    const listingsToLoad = listingIds.filter(id => !images[id] && !loading[id]);
    
    // If there are no listings to load, do nothing
    if (listingsToLoad.length === 0) return;

    // Use the batch API endpoint if available, otherwise fallback to individual requests
    if (listingsToLoad.length === 1) {
      loadImagesForListing(listingsToLoad[0]);
      return;
    }
    
    // Mark all listings as loading
    const newLoadingState = { ...loading };
    listingsToLoad.forEach(id => {
      newLoadingState[id] = true;
    });
    setLoading(newLoadingState);

    // Load all the images in a single request
    api.listings.getImagesForListings(listingsToLoad)
      .then(({ data, error }) => {
        if (error) {
          console.error('Error loading batch images:', error);
        } else if (data) {
          setImages(prev => ({ ...prev, ...data }));
        }
      })
      .catch(err => {
        console.error('Error loading batch images:', err);
      })
      .finally(() => {
        // Update loading state for all listings
        const updatedLoadingState = { ...loading };
        listingsToLoad.forEach(id => {
          updatedLoadingState[id] = false;
        });
        setLoading(updatedLoadingState);
      });
  }, [images, loading, loadImagesForListing]);

  // Provide a simple context value
  const context = {
    images,
    loading,
    loadImagesForListing,
    loadImagesForListings
  };

  return (
    <ListingImagesContext.Provider value={context}>
      {children}
    </ListingImagesContext.Provider>
  );
}

// Simple hook to use the context
export function useListingImages() {
  const context = useContext(ListingImagesContext);
  if (!context) {
    throw new Error('useListingImages must be used within a ListingImagesProvider');
  }
  return context;
} 