import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from './Icon';
import type { ProductListing, EquipmentCategory } from '../types/api';
import { equipmentCategories } from '../types/api';
import { ImageSlider } from './ImageSlider';
import { ImageModal } from './ImageModal';
import { CardImageSlider } from './CardImageSlider';
import { FavoriteButton } from './FavoriteButton';
import { motion } from 'framer-motion';
import { animations } from '../lib/animation-config';

// Custom event for opening the modal without navigation
interface OpenDetailModalEvent {
  id: string;
  sourceRect: DOMRect | null;
}

// Create a custom event for opening the modal
const openDetailModal = (detail: OpenDetailModalEvent) => {
  const event = new CustomEvent<OpenDetailModalEvent>('openDetailModal', {
    detail
  });
  window.dispatchEvent(event);
};

interface ListingCardProps {
  listing: ProductListing;
  selectedAttributes: string[];
  onImageClick: (listing: ProductListing, index: number) => void;
  isSelected?: boolean;
}

// Equipment labels for human-readable display
// Note: We import equipmentCategories for type consistency, but define our own more descriptive labels
// rather than using the short abbreviations from the original mapping (e.g., "AP" → "Autopilot")
const equipmentLabels: Record<string, string> = {
  // Avionics
  autopilot: 'Autopilot',
  gps: 'GPS',
  transponder: 'Transponder',
  audioPanel: 'Audio Panel',
  primaryFlightDisplay: 'PFD (Glass)',
  secondaryFlightDisplay: 'MFD (Glass)',
  engineMonitoring: 'Engine Monitoring',
  weatherSystem: 'Weather',
  adsBSystem: 'ADS-B',
  communication: 'Communications',
  backupInstruments: 'Backup Instruments',
  radar: 'Radar',
  
  // Common additional equipment
  fuelSystem: 'Fuel System',
  landingGear: 'Landing Gear',
  lights: 'Lighting',
  propeller: 'Propeller',
  seats: 'Seats',
  deIce: 'De-Ice System',
  aircondition: 'Air Conditioning',
  engine: 'Engine Modifications',
};

const getEquipmentLabel = (key: string): string => {
  return equipmentLabels[key] || key.replace(/([A-Z])/g, ' $1').trim();
};

const getAttributeLabel = (key: string): string => {
  const labels: Record<string, string> = {
    registration: 'Tail #',
    year: 'Year',
    totalTime: 'Total Time',
    engineTime: 'Engine Time',
    propTime: 'Prop Time',
    flightRules: 'Flight Rules',
    make: 'Make',
    model: 'Model',
    serialNumber: 'Serial #',
    usefulLoad: 'Useful Load'
  };
  return labels[key] || key;
};

// Function to calculate relative time string (e.g., "2 days ago")
const getRelativeTimeString = (dateString: string) => {
  try {
    const date = new Date(dateString);
    const now = new Date(); // Use actual current time
    
    const msPerDay = 1000 * 60 * 60 * 24;
    const msPerMonth = msPerDay * 30.44; // Average month length
    const msPerYear = msPerDay * 365.25; // Account for leap years
    
    // Check if the date is in the future
    if (date > now) {
      const diff = date.getTime() - now.getTime();
      const days = Math.ceil(diff / msPerDay);
      
      if (days === 1) {
        return "1 day from now";
      } else if (days < 30) {
        return `${days} days from now`;
      } else {
        const months = Math.floor(diff / msPerMonth);
        return `${months} month${months !== 1 ? 's' : ''} from now`;
      }
    }
    
    // Past date calculation
    const diff = now.getTime() - date.getTime();
    const days = Math.floor(diff / msPerDay);
    const months = Math.floor(diff / msPerMonth);
    const years = Math.floor(diff / msPerYear);
    
    // For recent dates, just show days
    if (days === 0) {
      return "today";
    } else if (days === 1) {
      return "yesterday";
    } else if (days < 30) {
      return `${days} days ago`;
    } else if (months < 12) {
      return `${months} month${months !== 1 ? 's' : ''} ago`;
    } else {
      return `${years} year${years !== 1 ? 's' : ''} ago`;
    }
  } catch (e) {
    console.error("Error calculating relative time:", e);
    return "";
  }
};

export const ListingCard = ({ 
  listing, 
  selectedAttributes,
  onImageClick,
  isSelected = false,
}: ListingCardProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const cardRef = useRef<HTMLDivElement>(null);

  const formatAttribute = (key: string, value: any) => {
    if (key === 'year') return value?.toString();
    if (typeof value === 'number') return value.toLocaleString();
    return value;
  };

  const handleCardClick = (e: React.MouseEvent) => {
    // Check if the click is on elements with explicit click behaviors
    // or elements that should not trigger navigation
    const target = e.target as HTMLElement;
    
    // Don't navigate if clicking on these elements
    if (
      target.closest('.card-image-slider') || // Image slider
      target.closest('a') || // Links
      target.closest('button') || // Buttons
      target.closest('details') || // Expandable details
      target.closest('summary') // Summary element
    ) {
      return;
    }

    // Get the card's rect for animation
    let sourceCardRect = null;
    if (cardRef.current) {
      sourceCardRect = cardRef.current.getBoundingClientRect();
    }
    
    // Use custom event to open modal without affecting scroll
    openDetailModal({
      id: listing.aircraftId || listing._id, // Use aircraftId if available, fallback to _id
      sourceRect: sourceCardRect
    });
  };

  // Animation variants for selected card
  const cardVariants = {
    selected: {
      opacity: 1,
      scale: 1.05,
      boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
      transition: {
        duration: animations.card.appear.duration,
        ease: animations.card.appear.ease
      }
    },
    normal: {
      opacity: 1,
      scale: 1,
      boxShadow: "0px 0px 0px rgba(0,0,0,0)",
      transition: {
        duration: animations.card.appear.duration,
        ease: animations.card.appear.ease
      }
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      transition: {
        duration: animations.card.disappear.duration,
        ease: animations.card.disappear.ease
      }
    }
  };
  
  // Watch for changes in isSelected to apply selected state
  useEffect(() => {
    // Mark card as selected for future reference
    if (isSelected && cardRef.current) {
      cardRef.current.setAttribute('data-was-selected', 'true');
    }
  }, [isSelected]);

  return (
    <motion.div 
      ref={cardRef}
      className="relative flex flex-col bg-white dark:bg-neutral-800 rounded-lg shadow-lg ring-1 ring-gray-200 dark:ring-neutral-800 cursor-pointer h-full"
      onClick={handleCardClick}
      variants={cardVariants}
      initial="normal"
      animate={isSelected ? "selected" : "normal"}
      exit="exit"
    >
      {/* Image Container */}
      <div className="relative w-full card-image-slider group">
        {/* Force 4:3 aspect ratio */}
        <div className="aspect-[4/3] w-full">
          <div className="absolute inset-0 bg-gray-100 dark:bg-neutral-800 rounded-t-lg overflow-hidden">
            <CardImageSlider
              firstImage={listing.firstImage}
              imageCount={listing.imageCount}
              currentIndex={currentImageIndex}
              onIndexChange={setCurrentImageIndex}
              onImageClick={(index) => onImageClick(listing, index)}
              listingId={listing.aircraftId || listing._id}
              aspectRatio="4:3"
            />
          </div>
        </div>
        
        {/* Favorite Button */}
        <div className="absolute top-2 right-2 z-10">
          <FavoriteButton 
            listingId={listing.aircraftId || listing._id}
            size="md"
            showCount={false}
          />
        </div>
      </div>

      {/* Content */}
      <div className="p-4 flex flex-col flex-1">
        {/* Title */}
        <div className="flex items-start justify-between gap-2 pb-2">
          <h3 className="font-semibold text-gray-900 dark:text-gray-100">
            {listing.modelInformation?.year} {listing.modelInformation?.make} {listing.modelInformation?.model}
          </h3>
          {listing.price > 0 && (
            <p className="text-base font-medium text-green-600 dark:text-green-500 flex-shrink-0">
              ${listing.price.toLocaleString()}
            </p>
          )}
        </div>

        {/* Attributes Grid */}
        <div className="mt-2 grid gap-y-2">
          <div className="grid grid-cols-3 gap-x-4 gap-y-2">
            {/* Basic attributes */}
            {selectedAttributes
              .filter(attr => !attr.startsWith('equipment.'))
              .map(attr => {
                // Get value from the appropriate object based on the attribute name
                let value;
                if (attr === 'registration' || attr === 'serialNumber') {
                  value = listing.listingAttributes?.[attr];
                } else if (attr === 'year' || attr === 'make' || attr === 'model') {
                  value = listing.modelInformation?.[attr];
                } else if (attr === 'totalTime') {
                  value = listing.airframeEngine?.airframeTimeHours;
                } else if (attr === 'engineTime') {
                  value = listing.airframeEngine?.engine1TimeHours;
                } else if (attr === 'propTime') {
                  value = listing.airframeEngine?.prop1TimeHours;
                } else if (attr === 'flightRules') {
                  value = listing.specifications?.flightRules;
                } else if (attr === 'usefulLoad') {
                  value = listing.specifications?.usefulLoadLbs;
                }
                
                // Skip rendering if no value exists
                if (value === undefined || value === null) {
                  return null;
                }

                // Format the attribute name and value
                let label = getAttributeLabel(attr);
                
                // Format value for display
                let displayValue = value;
                if (attr === 'totalTime' || attr === 'engineTime' || attr === 'propTime') {
                  displayValue = typeof value === 'number' ? `${value.toLocaleString()} hrs` : value;
                } else if (attr === 'usefulLoad') {
                  displayValue = typeof value === 'number' ? `${value.toLocaleString()} lbs` : value;
                }

                return (
                  <div key={attr} className="flex flex-col">
                    <dt className="text-xs text-gray-500 dark:text-gray-400">{label}</dt>
                    <dd className="text-sm font-medium text-gray-900 dark:text-gray-100">
                      {displayValue || <span className="text-gray-400 dark:text-gray-500">—</span>}
                    </dd>
                  </div>
                );
              })}
          </div>
          <div className="grid grid-cols-2 gap-x-6 gap-y-2">
            {/* Equipment */}
            {selectedAttributes
              .filter(attr => attr.startsWith('equipment.'))
              .map(attr => {
                const category = attr.split('.')[1] as EquipmentCategory;
                
                // Check first in the new schema (avionics & additionalEquipment)
                let equipment;
                
                // Check if this is an avionics item or additionalEquipment by naming convention
                const isAvionics = 
                  category.toLowerCase().includes('gps') || 
                  category.toLowerCase().includes('radio') ||
                  category.toLowerCase().includes('nav') ||
                  category.toLowerCase().includes('autopilot') ||
                  category.toLowerCase().includes('transponder') ||
                  category.toLowerCase().includes('ads') ||
                  category.toLowerCase().includes('efis');
                
                if (isAvionics && listing.avionics) {
                  equipment = listing.avionics[category];
                } else if (listing.additionalEquipment) {
                  equipment = listing.additionalEquipment[category];
                } 
                
                // If no equipment found, return null to skip rendering
                if (!equipment) {
                  return null;
                }
                
                return (
                  <div key={attr}>
                    <dt className="text-xs text-gray-500 dark:text-gray-400">
                      {getEquipmentLabel(category)}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                      {isAvionics && equipment?.models && equipment.models.length > 0 ? (
                        // Display models for avionics
                        equipment.models.length > 1 ? (
                          <details>
                            <summary>{equipment.models[0]}</summary>
                            <div className="mt-1 pl-2">
                              {equipment.models.slice(1).join(', ')}
                            </div>
                          </details>
                        ) : (
                          equipment.models[0]
                        )
                      ) : equipment?.details ? (
                        // Fallback to details if no models or for non-avionics
                        Array.isArray(equipment.details) && equipment.details.length > 0 ? (
                          equipment.details.length > 30 ? (
                            <details>
                              <summary>{equipment.details[0]}</summary>
                              <div className="mt-1 pl-2">
                                {equipment.details.slice(1).join(', ')}
                              </div>
                            </details>
                          ) : (
                            equipment.details.join(', ')
                          )
                        ) : (
                          equipment.details
                        )
                      ) : (
                        equipment.installed ? 'Installed' : (
                          <span className="text-gray-300 dark:text-gray-500 font-normal">Not Specified</span>
                        )
                      )}
                    </dd>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="mt-4 items-start flex flex-wrap gap-1.5 mb-4 overflow-hidden">
          {/* Process avionics for tags */}
          {listing.avionics && Object.entries(listing.avionics).map(([category, data], categoryIndex) => {
            // Skip if data is null or not installed
            if (!data || !data.installed) return null;
            
            // Avionics pill style - explicitly define for both model and details cases
            const avionicsPillStyle = "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium bg-blue-50 text-blue-800 dark:bg-neutral-900/60 dark:text-blue-400";
            
            // Check if we have models to display
            if (data.models && data.models.length > 0) {
              return data.models.map((model, index) => (
                <Link
                  key={`avionics-${categoryIndex}-${index}`}
                  to={`/listing/${listing.aircraftId || listing._id}`}
                  className={avionicsPillStyle}
                  onClick={(e) => e.stopPropagation()} // Prevent card click when clicking the link
                >
                  {model}
                </Link>
              ));
            }           
            return null;
          })}
        </div>

        {/* Footer */}
        <div className="mt-auto pt-4 border-t border-gray-200 dark:border-neutral-700 flex justify-between">
          <div className="flex items-center gap-2">
            {/* Source from scrapingSources */}
            {listing.scrapingSources && listing.scrapingSources.length > 0 ? (
              listing.scrapingSources.length === 1 ? (
                /* Single source */
                <div className="flex items-center">
                  <a 
                    href={listing.scrapingSources[0].url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-xs text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100"
                    onClick={(e) => e.stopPropagation()} // Prevent card click when clicking the link
                  >
                    {listing.scrapingSources[0].source}
                  </a>
                  {listing.scrapingSources[0].lastUpdatedDate && (
                    <div className="group/tooltip inline-flex items-center relative">
                      <span className="text-xs text-gray-500 dark:text-gray-400 ml-1">
                        | {getRelativeTimeString(String(listing.scrapingSources[0].lastUpdatedDate))}
                      </span>
                      <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-1 hidden group-hover/tooltip:block bg-gray-900 text-white text-xs rounded px-2 py-1 z-30 whitespace-nowrap">
                        Last updated: {new Date(String(listing.scrapingSources[0].lastUpdatedDate)).toLocaleDateString()}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                /* Multiple sources - show as dropdown */
                <div className="group/sources relative">
                  <span className="text-xs text-gray-700 dark:text-gray-300 cursor-pointer underline">
                    {listing.scrapingSources.length} Sources
                  </span>
                  <div className="hidden group-hover/sources:block absolute bottom-full left-0 mb-1 bg-white dark:bg-neutral-800 shadow-lg rounded-md p-2 z-20 min-w-[220px]">
                    <div className="flex flex-col gap-1">
                      {listing.scrapingSources.map((source, index) => (
                        <div key={`source-${index}`} className="flex items-center justify-between">
                          <a 
                            href={source.url} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-xs text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100 whitespace-nowrap"
                            onClick={(e) => e.stopPropagation()} // Prevent card click
                          >
                            {source.source}
                          </a>
                          {source.lastUpdatedDate && (
                            <span className="text-xs text-gray-500 dark:text-gray-400 ml-1 relative group/datetooltip">
                              {getRelativeTimeString(String(source.lastUpdatedDate))}
                              <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-1 hidden group-hover/datetooltip:block bg-gray-900 text-white text-xs rounded px-2 py-1 z-30 whitespace-nowrap">
                                Last updated: {new Date(String(source.lastUpdatedDate)).toLocaleDateString()}
                              </div>
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )
            ) : (
              <span className="text-xs text-gray-500">No source available</span>
            )}
          </div>
          {/* Replace the text span with a Link component */}
          <Link 
            to={`/listing/${listing.aircraftId || listing._id}`}
            className="text-xs font-medium text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100 flex items-center"
            onClick={(e) => e.stopPropagation()} // Prevent card click when clicking the link
          >
            More Info
            <Icon name="ArrowRight" size={14} className="inline ml-1" />
          </Link>
        </div>
      </div>

      <ImageModal 
        firstImage={listing.firstImage}
        imageCount={listing.imageCount}
        listingId={listing.aircraftId || listing._id}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        initialIndex={currentImageIndex}
      />
    </motion.div>
  );
}; 