import type { ProductListing, ListingsResponse } from '../types/api';
import { config } from '../config';
import type { QueryState } from '../types/queryState';
import { transformQueryToApiParams } from '../lib/api-transforms';

// Add this function to safely get window location
function getWindowLocation() {
  if (typeof window === 'undefined') return 'SSR';
  return window.location.href;
}

// Move logging into a function that runs after initialization
function logBuildInfo() {
  // Removed console.log
}

// Call it immediately in development
const isDevelopment = typeof window !== 'undefined' && window.location.hostname === 'localhost';
if (isDevelopment) {
  logBuildInfo();
}

interface ApiResponseWithData<T> {
  data: T;
  error: null;
}

interface ApiResponseWithError {
  data: null;
  error: string;
}

type ApiResponse<T> = ApiResponseWithData<T> | ApiResponseWithError;

// Build info - visible in browser console
const buildInfo = {
  version: '__VERSION__',
  buildTime: '__BUILD_TIME__',
  environment: process.env.NODE_ENV,
  apiUrl: config.apiUrl
};

// API client for interacting with the backend
export const api = {
  baseUrl: `${config.apiUrl}/api`,
  
  async fetchListings(params: Record<string, any> = {}): Promise<ListingsResponse> {
    const url = new URL(`${this.baseUrl}/listings`);
    
    // Add parameters if provided
    Object.entries(params).forEach(([key, value]) => {
      // Skip empty parameters
      if (value === undefined || value === null || value === '') {
        return;
      }
      
      // Handle arrays
      if (Array.isArray(value)) {
        if (value.length === 0) {
          return; // Skip empty arrays
        }
        
        // Instead of adding each array value as a separate parameter with the same name,
        // join them into a single comma-separated value and add once
        url.searchParams.append(key, value.sort().join(','));
      } else {
        // Add scalar value
        url.searchParams.append(key, value.toString());
      }
    });
    
    const response = await fetch(url.toString());
    
    if (!response.ok) {
      throw new Error(`API Error: ${response.status} ${response.statusText}`);
    }
    
    const data: ListingsResponse = await response.json();
    
    return data;
  },
  
  async fetchListing(id: string): Promise<any> {
    const url = new URL(`${this.baseUrl}/listing/${id}`);
    const response = await fetch(url.toString());
    
    if (!response.ok) {
      throw new Error(`API Error: ${response.status} ${response.statusText}`);
    }
    
    const data = await response.json();
    return data.listing;
  },

  listings: {
    getAll: async (params: Partial<QueryState>, options?: { signal?: AbortSignal }): Promise<ApiResponse<ListingsResponse>> => {
      try {
        // Transform query state to API parameters
        const apiParams = transformQueryToApiParams(params);
        
        // Build query string
        const queryString = new URLSearchParams();
        Object.entries(apiParams).forEach(([key, value]) => {
          if (value === undefined || value === null || value === '') {
            return;
          }
          
          if (Array.isArray(value)) {
            // For arrays, use a single parameter with all values joined by commas
            // This improves cache consistency when using Redis
            if (value.length > 0) {
              queryString.set(key, value.join(','));
            }
          } else {
            queryString.set(key, value.toString());
          }
        });
        
        const url = `${config.apiUrl}/products/listings?${queryString.toString()}`;

        const response = await fetch(url, {
          signal: options?.signal,
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        if (!response.ok) {
          throw new Error(`API error: ${response.status}`);
        }
        
        const data = await response.json();
        
        return { data, error: null };
      } catch (error: unknown) {
        if (error instanceof Error && error.name === 'AbortError') {
          throw error;
        }
        return {
          data: null,
          error: error instanceof Error ? error.message : 'An unknown error occurred'
        };
      }
    },
    
    getById: async (id: string): Promise<ApiResponse<ProductListing>> => {
      try {
        const response = await fetch(`${config.apiUrl}/products/listings/${id}`);
        if (!response.ok) {
          throw new Error('Listing not found');
        }
        const data = await response.json();
        return { data, error: null };
      } catch (error) {
        return { 
          data: null,
          error: error instanceof Error ? error.message : 'Failed to fetch listing'
        };
      }
    },

    getImages: async (id: string): Promise<ApiResponse<{ images: Array<{ th?: string; hq?: string }> }>> => {
      try {
        const response = await fetch(`${config.apiUrl}/products/listings/${id}/images`);
        if (!response.ok) {
          throw new Error('Failed to fetch listing images');
        }
        const data = await response.json();
        return { data, error: null };
      } catch (error) {
        return {
          data: null,
          error: error instanceof Error ? error.message : 'Failed to fetch listing images'
        };
      }
    },

    getImagesForListings: async (ids: string[]): Promise<ApiResponse<Record<string, Array<{ th?: string; hq?: string }>>>> => {
      try {
        const response = await fetch(`${config.apiUrl}/products/listings/images?ids=${ids.join(',')}`);
        if (!response.ok) {
          throw new Error('Failed to fetch listing images');
        }
        const data = await response.json();
        return { data, error: null };
      } catch (error) {
        return {
          data: null,
          error: error instanceof Error ? error.message : 'Failed to fetch listing images'
        };
      }
    }
  }
}; 