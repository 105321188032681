import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Standard button styles with consistent border colors
 */
export const buttonStyles = {
  base: cn(
    'transition-all duration-300',
    'border border-gray-300 dark:border-neutral-700',
    'bg-white dark:bg-neutral-900',
    'text-gray-700 dark:text-neutral-300',
    'hover:bg-gray-50 dark:hover:bg-neutral-800',
    'focus:outline-none focus:ring focus:ring-blue-500/20 focus:border-blue-500'
  ),
  rounded: 'rounded-xl',
  roundedFull: 'rounded-full',
  shadow: 'shadow-sm',
  sizes: {
    sm: 'px-2 py-1 text-xs',
    md: 'px-3 py-2 text-sm',
    lg: 'px-4 py-2 text-base',
  },
  icon: {
    sm: 'w-8 h-8',
    md: 'w-10 h-10',
    lg: 'w-12 h-12',
  },
};

/**
 * Standard input styles with consistent border colors
 */
export const inputStyles = {
  base: cn(
    'transition-all duration-300',
    'border border-gray-200 dark:border-neutral-700',
    'bg-white dark:bg-neutral-900',
    'text-gray-700 dark:text-neutral-300',
    'placeholder:text-gray-400 dark:placeholder:text-neutral-500',
    'focus:outline-none focus:ring focus:ring-blue-500/20 focus:border-blue-500'
  ),
  rounded: 'rounded-xl',
  shadow: 'shadow-sm',
  sizes: {
    sm: 'px-2 py-1 text-xs',
    md: 'px-3 py-2 text-sm',
    lg: 'px-4 py-2 text-base',
  },
};

/**
 * Standard pill styles for filter tags and similar elements
 */
export const pillStyles = {
  base: cn(
    'inline-flex items-center',
    'text-[14px] leading-none',
    'transition-colors font-medium rounded-md',
    'm-1'
  ),
  sizes: {
    sm: 'px-2.5 py-1 text-[12px]',
    md: 'px-3 py-1.5 text-[14px]',
    lg: 'px-3.5 py-1.5 text-[14px]',
  },
  variants: {
    default: cn(
      'bg-white dark:bg-neutral-900',
      'text-gray-700 dark:text-neutral-300',
      'hover:bg-gray-50 dark:hover:bg-neutral-800',
      'border border-gray-200 dark:border-neutral-700'
    ),
    active: cn(
      'bg-blue-200 dark:bg-blue-800',
      'text-blue-900 dark:text-blue-50',
      'hover:bg-blue-300 dark:hover:bg-blue-900',
      'border border-blue-200 dark:border-blue-700'
    ),
    modelFamily: cn(
      'bg-blue-300/30 dark:bg-blue-800/70',
      'text-blue-900 dark:text-blue-50',
      'hover:bg-blue-200 dark:hover:bg-blue-900',
      'border border-transparent dark:border-blue-600'
    ),
    inactive: cn(
      'bg-gray-100 hover:bg-gray-100 dark:bg-neutral-800 dark:hover:bg-neutral-700',
      'text-gray-600 dark:text-neutral-400',
      'border border-gray-200 dark:border-neutral-700'
    ),
    blue: cn(
      'bg-blue-50 dark:bg-blue-900/20',
      'text-blue-800 dark:text-blue-400',
      'hover:bg-blue-100 dark:hover:bg-blue-900/30',
      'border border-blue-200 dark:border-blue-800/30'
    ),
    green: cn(
      'bg-green-50 dark:bg-green-900/20',
      'text-green-600 dark:text-green-400',
      'hover:bg-green-100 dark:hover:bg-green-900/30',
      'border border-green-200 dark:border-green-800/30'
    ),
    red: cn(
      'bg-red-50 dark:bg-red-900/20',
      'text-red-600 dark:text-red-400',
      'hover:bg-red-100 dark:hover:bg-red-900/30',
      'border border-red-200 dark:border-red-800/30'
    ),
    yellow: cn(
      'bg-yellow-50 dark:bg-yellow-900/20',
      'text-yellow-600 dark:text-yellow-400',
      'hover:bg-yellow-100 dark:hover:bg-yellow-900/30',
      'border border-yellow-200 dark:border-yellow-800/30'
    ),
  },
  count: cn(
    'ml-1.5 text-[12px] leading-none opacity-70 mt-[2px]'
  ),
  xButton: cn(
    'transition-colors duration-200',
    'focus:outline-none',
    'text-blue-900 dark:text-blue-50',
    'dark:hover:text-neutral-400',
    'ml-1.5'
  ),
  clearAllButton: cn(
    'max-h-[30px] ml-0'
  ),
  group: {
    container: 'flex flex-wrap relative z-[40] min-h-[28px]',
    title: 'text-sm flex-nowrap pt-[7px] ml-3 font-medium text-gray-500 dark:text-gray-400',
    pillsContainer: 'flex flex-wrap items-center gap-.5',
    groupContainer: 'flex flex-row sm:flex-row sm:items-top gap-1.5 mr-1 mb-1 sm:mb-0 flex-nowrap'
  }
};

/**
 * Capitalize model names with special rules
 * - Words with 4 or fewer characters should be all caps
 * - Other words have just the first letter capitalized
 */
export function formatModelName(label: string): string {
  return label.toLowerCase().split(/\b/).map(part => {
    // Skip non-word parts (spaces, punctuation)
    if (!/[a-z0-9]/i.test(part)) return part;
    
    // If word has 4 chars or less, make it all caps
    if (part.length <= 4 && /[a-z]/i.test(part)) {
      return part.toUpperCase();
    }
    
    // Otherwise just capitalize first letter
    return part.charAt(0).toUpperCase() + part.slice(1);
  }).join('');
}

/**
 * Capitalize make names with special rules
 * - Words with 3 or fewer characters should be all caps
 * - Other words have just the first letter capitalized
 */
export function formatMakeName(label: string): string {
  return label.toLowerCase().split(/\b/).map(part => {
    // Skip non-word parts (spaces, punctuation)
    if (!/[a-z0-9]/i.test(part)) return part;
    
    // If word has 3 chars or less, make it all caps
    if (part.length <= 3 && /[a-z]/i.test(part)) {
      return part.toUpperCase();
    }
    
    // Otherwise just capitalize first letter
    return part.charAt(0).toUpperCase() + part.slice(1);
  }).join('');
}

/**
 * Title case formatting for most labels
 */
export function formatTitleCase(label: string): string {
  return label.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase());
}

/**
 * Extract make information from model family label
 * Handles formats like "Make: ModelFamily" or "Make - ModelFamily"
 * @param label The label to parse
 * @returns An object with make and modelFamily properties
 */
export function extractMakeAndModelFamily(label: string): { make: string, modelFamily: string } {
  // Check for "Make: ModelFamily" format first (from filter pill labels)
  let match = label.match(/^Make: (.*?) - (.*)$/);
  if (match) {
    return {
      make: match[1],
      modelFamily: match[2]
    };
  }
  
  // Check for "ModelFamily: ModelFamily" format (from filter pill labels)
  match = label.match(/^Model Family: (.*)$/);
  if (match && match[1].includes(' - ')) {
    const parts = match[1].split(' - ');
    return {
      make: parts[0],
      modelFamily: parts[1]
    };
  }
  
  // Check for simple "Make - ModelFamily" format
  match = label.match(/^(.*?) - (.*)$/);
  if (match) {
    return {
      make: match[1],
      modelFamily: match[2]
    };
  }
  
  // If no recognized format, return the whole thing as modelFamily
  return {
    make: '',
    modelFamily: label
  };
} 