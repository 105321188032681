import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
// Import early to initialize scrollbar width measurement
import './utils/scrollbarWidth'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <App />
)
