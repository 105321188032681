import { useMemo } from 'react';
import type { Option, EquipmentCategory } from '../types/api';
import type { QueryState, SortOption } from '../types/queryState';
import { RANGE_CONFIGS } from '../shared/range-config';

export interface AvailableFilters {
  makes: Option[];
  modelFamilies: Array<Option & { make: string }>;
  models: Array<Option & { make: string; modelFamily?: string }>;
  years: { min: number; max: number };
  conditions?: Option[];
  saleStatuses?: Option[];
  intendedUses?: Option[];
  categories?: Option[];
}

type RangeType = 'price' | 'year' | 'engineTime' | 'usefulLoad';

export function useFilterHandlers(
  queryState: QueryState,
  setQueryState: (state: Partial<QueryState>) => void,
  availableFilters: AvailableFilters
) {
  /**
   * Handle make filter changes with intelligent model family and model preservation
   */
  const handleMakeChange = (selected: Option[] | null) => {
    if (!selected || selected.length === 0) {
      // When clearing make, also clear model families and models
      setQueryState({ 
        makes: [],
        modelFamilies: [],
        models: [],
        page: 1 
      });
      return;
    }
    
    const newMakes = selected.map(item => item.value.toLowerCase());
    const previousMakesCount = queryState.makes?.length || 0;
    
    if (previousMakesCount === 0) {
      // If we're adding the first make, clear model selections
      setQueryState({ 
        makes: newMakes,
        modelFamilies: [],
        models: [],
        page: 1
      });
    } else if (newMakes.length < previousMakesCount) {
      // If we're removing makes, we need to filter model families and models
      
      // Create a set of makes for quick lookup
      const makeSet = new Set(newMakes.map(make => String(make).toUpperCase()));
      
      // Filter model families to keep only those belonging to the remaining makes
      const keptModelFamilies = queryState.modelFamilies.filter(familyValue => {
        // Find the model family in available model families to get its make
        const family = availableFilters.modelFamilies.find(mf => mf.value === familyValue);
        // Only keep if family exists and its make is still selected
        return family && family.make && makeSet.has(String(family.make).toUpperCase());
      });
      
      // Create a set of model families for quick lookup
      const modelFamilySet = new Set(keptModelFamilies.map(mf => String(mf).toUpperCase()));
      
      // Filter models to keep only those belonging to the remaining model families
      const keptModels = queryState.models.filter(modelValue => {
        // Find the model in available models to get its model family
        const model = availableFilters.models.find(m => m.value === modelValue);
        // Only keep if model exists and its model family is still selected
        return model && model.modelFamily && 
               modelFamilySet.has(String(model.modelFamily).toUpperCase());
      });
      
      setQueryState({ 
        makes: newMakes,
        modelFamilies: keptModelFamilies,
        models: keptModels,
        page: 1
      });
    } else {
      // If we're adding additional makes, preserve existing modelFamily and model selections
      setQueryState({ 
        makes: newMakes,
        page: 1
      });
    }
  };

  /**
   * Handle model family filter changes with intelligent model preservation
   */
  const handleModelFamilyChange = (selected: Option[] | null) => {
    if (!selected || selected.length === 0) {
      // When clearing model family, also clear models
      setQueryState({ 
        modelFamilies: [],
        models: [],
        page: 1 
      });
      return;
    }
    
    // Get new model family values
    const newModelFamilies = selected.map(item => item.value.toLowerCase());
    
    // Check if we're adding new model families
    const isAddingModelFamilies = newModelFamilies.length > queryState.modelFamilies.length;
    
    if (isAddingModelFamilies) {
      // If we're adding new model families, preserve the existing model selections
      setQueryState({ 
        modelFamilies: newModelFamilies,
        page: 1
      });
    } else {
      // If we're removing model families, filter out models that belong to removed families
      
      // Create a set of model families for quick lookup
      const modelFamilySet = new Set(newModelFamilies.map(mf => String(mf).toUpperCase()));
      
      // Filter models to keep only those that belong to the remaining model families
      const keptModels = queryState.models.filter(modelValue => {
        // Find the model in available models to get its model family
        const model = availableFilters.models.find(m => m.value === modelValue);
        // Only keep if model exists and its model family is still selected
        return model && model.modelFamily && modelFamilySet.has(String(model.modelFamily).toUpperCase());
      });
      
      setQueryState({ 
        modelFamilies: newModelFamilies,
        models: keptModels,
        page: 1
      });
    }
  };

  /**
   * Handle model filter changes
   */
  const handleModelChange = (selected: Option[] | null) => {
    if (!selected || selected.length === 0) {
      setQueryState({ 
        models: [],
        page: 1 
      });
      return;
    }
    
    setQueryState({ 
      models: selected.map(item => item.value.toLowerCase()),
      page: 1
    });
  };

  /**
   * Handle range filter changes (price, year, engineTime, usefulLoad)
   */
  const handleRangeChange = (type: RangeType, values: [number, number]) => {
    if (type === 'price') {
      setQueryState({ 
        priceRange: values,
        page: 1
      });
    } else if (type === 'year') {
      setQueryState({ 
        yearRange: values,
        page: 1
      });
    } else if (type === 'engineTime') {
      setQueryState({ 
        engineTimeRange: values,
        page: 1
      });
    } else if (type === 'usefulLoad') {
      setQueryState({ 
        usefulLoadRange: values,
        page: 1
      });
    }
  };

  /**
   * Handle equipment filter changes
   */
  const handleEquipmentChange = (equipment: Record<EquipmentCategory, boolean>) => {
    setQueryState({ 
      equipment,
      page: 1
    });
  };

  /**
   * Handle condition filter changes
   */
  const handleConditionChange = (selected: Option | null) => {
    setQueryState({ 
      condition: selected?.value ? selected.value.toLowerCase() : '',
      page: 1
    });
  };

  /**
   * Handle sale status filter changes
   */
  const handleSaleStatusChange = (selected: Option | Option[] | null) => {
    if (!selected) {
      setQueryState({ 
        saleStatus: '',
        page: 1
      });
      return;
    }
    
    if (Array.isArray(selected)) {
      setQueryState({ 
        saleStatus: selected.map(opt => opt.value.toLowerCase()).join(','),
        page: 1
      });
    } else {
      setQueryState({ 
        saleStatus: selected.value.toLowerCase(),
        page: 1
      });
    }
  };

  /**
   * Handle intended use filter changes
   */
  const handleIntendedUseChange = (selected: Option | Option[] | null) => {
    if (!selected) {
      setQueryState({ 
        intendedUse: [],
        page: 1
      });
      return;
    }
    
    if (Array.isArray(selected)) {
      setQueryState({ 
        intendedUse: selected.map(opt => opt.value.toLowerCase()),
        page: 1
      });
    } else {
      setQueryState({ 
        intendedUse: [selected.value.toLowerCase()],
        page: 1
      });
    }
  };

  /**
   * Handle category filter changes
   */
  const handleCategoryChange = (selected: Option | null) => {
    setQueryState({ 
      category: selected?.value ? selected.value.toLowerCase() : '',
      page: 1
    });
  };

  /**
   * Handle show sold filter changes
   */
  const handleShowSoldChange = (checked: boolean) => {
    setQueryState({ 
      showSold: checked,
      page: 1
    });
  };

  /**
   * Handle show zero price filter changes
   */
  const handleShowZeroPriceChange = (checked: boolean) => {
    setQueryState({ 
      showZeroPrice: checked,
      page: 1
    });
  };

  /**
   * Handle sort changes
   */
  const handleSortChange = (sort: SortOption) => {
    setQueryState({ 
      sort,
      page: 1
    });
  };

  /**
   * Create selected option objects based on current filter state
   */
  const selectedOptions = useMemo(() => {
    return {
      // Make selected makes into option objects with metadata from available filters
      selectedMakes: queryState.makes.map(value => {
        const make = availableFilters.makes.find(m => m.value === value);
        return {
          value,
          label: make?.label || value,
          count: make?.count || 0,
          zeroCount: make?.zeroCount || 0
        };
      }),

      // Model families with make information
      selectedModelFamilies: queryState.modelFamilies.map(familyId => {
        const family = availableFilters.modelFamilies.find(f => f.value === familyId);
        return {
          value: familyId,
          label: family?.label || familyId,
          count: family?.count || 0,
          zeroCount: family?.zeroCount || 0,
          make: family?.make || ''
        };
      }),

      // Models with make and model family information
      selectedModels: queryState.models.map(modelId => {
        const model = availableFilters.models.find(m => m.value === modelId);
        return {
          value: modelId,
          label: model?.label || modelId,
          count: model?.count || 0,
          zeroCount: model?.zeroCount || 0,
          make: model?.make || '',
          modelFamily: model?.modelFamily || ''
        };
      }),

      // Create option objects for dropdowns
      selectedCondition: queryState.condition 
        ? { value: queryState.condition, label: queryState.condition, count: 0, zeroCount: 0 } 
        : null,
        
      selectedSaleStatuses: queryState.saleStatus 
        ? queryState.saleStatus.split(',').map(value => ({ 
            value, 
            label: value, 
            count: 0, 
            zeroCount: 0 
          }))
        : [],
        
      selectedIntendedUses: queryState.intendedUse.map(value => ({ 
        value, 
        label: value, 
        count: 0, 
        zeroCount: 0 
      })),
      
      selectedCategory: queryState.category 
        ? { value: queryState.category, label: queryState.category, count: 0, zeroCount: 0 } 
        : null,
    };
  }, [
    queryState.makes, 
    queryState.modelFamilies, 
    queryState.models, 
    queryState.condition,
    queryState.saleStatus,
    queryState.intendedUse,
    queryState.category,
    availableFilters.makes,
    availableFilters.modelFamilies,
    availableFilters.models
  ]);

  // Return all filter handlers and selected options
  return {
    // Filter handlers
    handleMakeChange,
    handleModelFamilyChange,
    handleModelChange,
    handleRangeChange,
    handleEquipmentChange,
    handleConditionChange,
    handleSaleStatusChange,
    handleIntendedUseChange,
    handleCategoryChange,
    handleShowSoldChange,
    handleShowZeroPriceChange,
    handleSortChange,
    
    // Selected options for UI components
    ...selectedOptions
  };
} 