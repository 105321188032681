// Determine if we're in production based on the current URL
const isProduction = typeof window !== 'undefined' && (
  window.location.hostname === 'alltheplanes.com' ||
  window.location.hostname === 'www.alltheplanes.com' ||
  window.location.hostname === 'd34y7o7eazgpz2.cloudfront.net'
);

console.log('Current hostname:', typeof window !== 'undefined' ? window.location.hostname : 'SSR');
console.log('Environment:', isProduction ? 'production' : 'development');

export const config = {
  apiUrl: isProduction 
    ? 'https://api.alltheplanes.com/api'
    : 'http://localhost:3001/api',
  environment: isProduction ? 'production' : 'development'
} as const;

console.log('Using API URL:', config.apiUrl);
