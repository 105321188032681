import { useState, useEffect, useRef, useMemo } from 'react';
import { Icon } from './Icon';
import { cn, buttonStyles, pillStyles } from '../lib/utils';
import RangeSlider from 'react-range-slider-input';
import type { Option, EquipmentCategory } from '../types/api';
import { equipmentCategories } from '../types/api';
import { EquipmentFilter } from './EquipmentFilter';
import { FilterModal } from './FilterModal';
import type { QueryState, SortOption } from '../types/queryState';
import { SelectDropdown, defaultFormatOptionLabel } from './SelectDropdown';
import 'react-range-slider-input/dist/style.css';
import FilterPills from './FilterPills';
import { RANGE_CONFIGS, getRangeDefaults } from '../shared/range-config.js';
import { useFilterHandlers, AvailableFilters } from '../hooks/useFilterHandlers';
import { motion, AnimatePresence } from 'framer-motion';
import { animations } from '../lib/animation-config';

interface SearchAndFilterProps {
  onFilterChange: (filters: Partial<QueryState>) => void;
  availableFilters: AvailableFilters;
  currentFilters: QueryState;
  showZeroPrice: boolean;
  totalCount: number;
  onClearAll: () => void;
  compactMobile?: boolean;
  hideFilterPills?: boolean;
}

// The format of a filter option
interface FilterOption {
  label: string;
  value: string;
  count?: number;
  zeroCount?: number;
}

interface ActiveFilter {
  type: 'make' | 'modelFamily' | 'model' | 'range' | 'equipment' | 'condition' | 'saleStatus' | 'intendedUse' | 'category';
  label: string;
  onRemove: () => void;
  value?: string | number | [number, number];
  data?: any;
}

export const SearchAndFilter = ({
  onFilterChange,
  availableFilters,
  currentFilters,
  showZeroPrice,
  totalCount,
  onClearAll,
  compactMobile,
  hideFilterPills
}: SearchAndFilterProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  // Use our centralized filter handlers
  const filterHandlers = useFilterHandlers(
    currentFilters, 
    onFilterChange, 
    availableFilters
  );
  
  const defaultRanges = useMemo(() => ({
    price: getRangeDefaults('priceRange'),
    year: [availableFilters.years.min, availableFilters.years.max] as [number, number],
    engineTime: getRangeDefaults('engineTimeRange'),
    usefulLoad: getRangeDefaults('usefulLoadRange')
  }), [availableFilters.years.min, availableFilters.years.max]);

  // Get all active filters for pills
  const getActiveFilters = (): ActiveFilter[] => {
    const filters: ActiveFilter[] = [];

    // Add makes filters
    currentFilters.makes.forEach(make => {
      const makeObj = availableFilters.makes.find(m => m.value === make);
      filters.push({
        type: 'make',
        label: `Make: ${makeObj?.label || make}`,
        onRemove: () => {
          const updatedMakes = currentFilters.makes.filter(m => m !== make);
          // When removing the last make, also clear model families and models
          if (updatedMakes.length === 0) {
            onFilterChange({ makes: [], modelFamilies: [], models: [] });
          } else {
            onFilterChange({ makes: updatedMakes });
          }
        }
      });
    });

    // Add model family filters
    filterHandlers.selectedModelFamilies.forEach(family => {
      filters.push({
        type: 'modelFamily',
        label: `Family: ${family.label}`,
        onRemove: () => {
          const updatedFamilies = currentFilters.modelFamilies.filter(f => f !== family.value);
          onFilterChange({ modelFamilies: updatedFamilies });
        },
        data: {
          make: family.make
        }
      });
    });

    // Add model filters
    filterHandlers.selectedModels.forEach(model => {
      filters.push({
        type: 'model',
        label: `Model: ${model.label}`,
        onRemove: () => {
          const updatedModels = currentFilters.models.filter(m => m !== model.value);
          onFilterChange({ models: updatedModels });
        },
        data: {
          make: model.make,
          modelFamily: model.modelFamily
        }
      });
    });

    // Add equipment filters
    Object.entries(currentFilters.equipment)
      .filter(([_, value]) => value)
      .forEach(([category, _]) => {
        const equipmentLabel = equipmentCategories[category as EquipmentCategory];
        filters.push({
          type: 'equipment',
          label: `Equipment: ${equipmentLabel}`,
          onRemove: () => {
            const updatedEquipment = { ...currentFilters.equipment };
            updatedEquipment[category as EquipmentCategory] = false;
            filterHandlers.handleEquipmentChange(updatedEquipment);
          }
        });
      });

    // Add condition filter
    if (currentFilters.condition) {
      // Format the condition with title case instead of keeping all uppercase
      const formattedCondition = currentFilters.condition
        .split(/[\s-]+/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
      
      filters.push({
        type: 'condition',
        label: `Condition: ${formattedCondition}`,
        onRemove: () => {
          onFilterChange({ condition: '' });
        }
      });
    }

    // Add saleStatus filter
    if (currentFilters.saleStatus) {
      // Format the sale status with title case instead of keeping all uppercase
      const formattedSaleStatus = currentFilters.saleStatus
        .split(/[\s-]+/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
      
      filters.push({
        type: 'saleStatus',
        label: `Status: ${formattedSaleStatus}`,
        onRemove: () => {
          onFilterChange({ saleStatus: '' });
        }
      });
    }

    // Add intendedUse filters
    currentFilters.intendedUse.forEach(use => {
      // Format the intended use with title case instead of keeping all uppercase
      const formattedUse = use
        .split(/[\s-]+/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
      
      filters.push({
        type: 'intendedUse',
        label: `Use: ${formattedUse}`,
        onRemove: () => {
          const updatedUses = currentFilters.intendedUse.filter(u => u !== use);
          onFilterChange({ intendedUse: updatedUses });
        }
      });
    });

    // Add category filter
    if (currentFilters.category) {
      // Format the category with title case instead of keeping all uppercase
      const formattedCategory = currentFilters.category
        .split(/[\s-]+/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
      
      filters.push({
        type: 'category',
        label: `Category: ${formattedCategory}`,
        onRemove: () => {
          onFilterChange({ category: '' });
        }
      });
    }

    // Add price range filter if not default
    if (
      currentFilters.priceRange[0] !== defaultRanges.price[0] ||
      currentFilters.priceRange[1] !== defaultRanges.price[1]
    ) {
      const min = formatPrice(currentFilters.priceRange[0]);
      const max = formatPrice(currentFilters.priceRange[1]);

      filters.push({
        type: 'range',
        label: `Price: ${min} - ${max}`,
        onRemove: () => {
          onFilterChange({ priceRange: defaultRanges.price });
        },
        value: currentFilters.priceRange
      });
    }

    // Add year range filter if not default
    if (
      (currentFilters.yearRange[0] !== defaultRanges.year[0] ||
      currentFilters.yearRange[1] !== defaultRanges.year[1]) && 
      // Only add if there's a query param explicitly specifying the year range
      window.location.search.includes('yearRange=')
    ) {
      filters.push({
        type: 'range',
        label: `Year: ${currentFilters.yearRange[0]} - ${currentFilters.yearRange[1]}`,
        onRemove: () => {
          onFilterChange({ yearRange: defaultRanges.year });
        },
        value: currentFilters.yearRange
      });
    }

    // Add engine time range filter if not default
    if (
      currentFilters.engineTimeRange[0] !== defaultRanges.engineTime[0] ||
      currentFilters.engineTimeRange[1] !== defaultRanges.engineTime[1]
    ) {
      filters.push({
        type: 'range',
        label: `Engine Hours: ${currentFilters.engineTimeRange[0]} - ${currentFilters.engineTimeRange[1]}`,
        onRemove: () => {
          onFilterChange({ engineTimeRange: defaultRanges.engineTime });
        },
        value: currentFilters.engineTimeRange
      });
    }

    // Add useful load range filter if not default
    if (
      currentFilters.usefulLoadRange[0] !== defaultRanges.usefulLoad[0] ||
      currentFilters.usefulLoadRange[1] !== defaultRanges.usefulLoad[1]
    ) {
      filters.push({
        type: 'range',
        label: `Useful Load: ${currentFilters.usefulLoadRange[0]} - ${currentFilters.usefulLoadRange[1]} lbs`,
        onRemove: () => {
          onFilterChange({ usefulLoadRange: defaultRanges.usefulLoad });
        },
        value: currentFilters.usefulLoadRange
      });
    }

    // Zero Price filter if active
    if (showZeroPrice) {
      filters.push({
        type: 'range',
        label: `Show Aircraft with No Price`,
        onRemove: () => {
          filterHandlers.handleShowZeroPriceChange(false);
        }
      });
    }

    // Show Sold filter if active
    if (currentFilters.showSold) {
      filters.push({
        type: 'range',
        label: `Include Sold Aircraft`,
        onRemove: () => {
          onFilterChange({ showSold: false });
        }
      });
    }

    return filters;
  };

  // Utility function to format price
  const formatPrice = (value: number) => {
    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `$${(value / 1000).toFixed(0)}K`;
    } else {
      return `$${value}`;
    }
  };

  return (
    <div className="space-y-4">
      <div> {/* Reserve space for pills to prevent layout shift */}
        {!hideFilterPills && getActiveFilters().length > 0 && (
          <div className="mb-4 empty:mb-0">
            <FilterPills 
              filters={getActiveFilters()}
              onClearAll={onClearAll}
              showClearAll={true}
            />
          </div>
        )}
      </div>

      {/* Filter Modal */}
      <FilterModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        selectedMakes={filterHandlers.selectedMakes}
        selectedModelFamilies={filterHandlers.selectedModelFamilies}
        selectedModels={filterHandlers.selectedModels}
        ranges={{
          price: currentFilters.priceRange,
          year: currentFilters.yearRange,
          engineTime: currentFilters.engineTimeRange,
          usefulLoad: currentFilters.usefulLoadRange
        }}
        availableFilters={availableFilters}
        onMakeChange={filterHandlers.handleMakeChange}
        onModelFamilyChange={filterHandlers.handleModelFamilyChange}
        onModelChange={filterHandlers.handleModelChange}
        onRangeChange={filterHandlers.handleRangeChange}
        showZeroPrice={showZeroPrice}
        onShowZeroPriceChange={filterHandlers.handleShowZeroPriceChange}
        showSold={currentFilters.showSold}
        onShowSoldChange={filterHandlers.handleShowSoldChange}
        totalMatches={totalCount}
        onClearAll={onClearAll}
        onFilterChange={onFilterChange}
        equipment={currentFilters.equipment as Record<EquipmentCategory, boolean>}
        onEquipmentChange={filterHandlers.handleEquipmentChange}
        selectedCondition={filterHandlers.selectedCondition}
        selectedSaleStatuses={filterHandlers.selectedSaleStatuses}
        selectedIntendedUses={filterHandlers.selectedIntendedUses}
        selectedCategory={filterHandlers.selectedCategory}
        onConditionChange={filterHandlers.handleConditionChange}
        onSaleStatusChange={filterHandlers.handleSaleStatusChange}
        onIntendedUseChange={filterHandlers.handleIntendedUseChange}
        onCategoryChange={filterHandlers.handleCategoryChange}
        sort={currentFilters.sort}
        onSortChange={filterHandlers.handleSortChange}
        columnCount={3}
        onLayoutChange={() => {}}
        onCustomizeClick={() => {}}
      />

      {/* Range Slider Styles */}
      <style>{`
        .range-slider {
          height: 4px !important;
          padding: 0 !important;
          background: var(--tw-neutral-200) !important;
        }
        
        .dark .range-slider {
          background: var(--tw-neutral-800) !important;
        }
        
        .range-slider .range-slider__range {
          background: var(--tw-blue-800) !important;
          height: 4px !important;
        }
        
        .range-slider .range-slider__thumb {
          width: 1.25rem !important;
          height: 1.25rem !important;
          background: var(--tw-white) !important;
          border: 2px solid var(--tw-blue-800) !important;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
        }

        .dark .range-slider .range-slider__thumb {
          background: var(--tw-neutral-100) !important;
          border: 2px solid var(--tw-blue-800) !important;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4) !important;
        }
      `}</style>
    </div>
  );
}; 

// Theme constants
export const theme = {
  layout: {
    button: 'h-10 rounded-md transition-colors',
    active: 'bg-gray-100 text-gray-900',
    inactive: 'hover:bg-gray-50'
  },
}; 

// The formatOptionLabel and selectClassNames are now imported from SelectDropdown.tsx 