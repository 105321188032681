// import React from 'react';
import { Icon } from './Icon';
import { cn, pillStyles, formatMakeName, formatModelName, formatTitleCase, extractMakeAndModelFamily } from '../lib/utils';
import { motion } from 'framer-motion';

interface Filter {
  type: string;
  label: string;
  onRemove: () => void;
  count?: number;  // Added count for displaying numbers
  data?: {
    make?: string;
    modelFamily?: string;
  };
}

interface FilterPillsProps {
  filters: Array<{
    type: string;
    label: string;
    onRemove: () => void;
    count?: number;  // Added count for displaying numbers
    data?: {
      make?: string;
      modelFamily?: string;
    };
  }>;
  onClearAll: () => void;
  showClearAll?: boolean;
  variant?: keyof typeof pillStyles.variants;
  size?: keyof typeof pillStyles.sizes;
}

export const FilterPills = ({ 
  filters, 
  onClearAll, 
  showClearAll = true,
  variant = 'active',
  size = 'md'
}: FilterPillsProps) => {
  if (filters.length === 0) return null;

  // Group filters by category
  const groupedFilters = {
    makeModel: filters.filter(f => f.type === 'make' || f.type === 'modelFamily' || f.type === 'model'),
    equipment: filters.filter(f => f.type === 'equipment'),
    year: filters.filter(f => f.type === 'year'),
    price: filters.filter(f => f.type === 'price'),
    engineTime: filters.filter(f => f.type === 'engineTime'),
    usefulLoad: filters.filter(f => f.type === 'usefulLoad'),
    specifications: filters.filter(f => f.type === 'condition' || f.type === 'category'),
    status: filters.filter(f => f.type === 'saleStatus' || f.type === 'intendedUse'),
    other: filters.filter(f => !['make', 'modelFamily', 'model', 'equipment', 'year', 'price', 'engineTime', 'usefulLoad', 'condition', 'category', 'saleStatus', 'intendedUse'].includes(f.type))
  };

  // Calculate ordered make-model filters
  const getOrderedMakeModelFilters = () => {
    const makeFilters = [...groupedFilters.makeModel.filter(f => f.type === 'make')];
    const modelFamilyFilters = [...groupedFilters.makeModel.filter(f => f.type === 'modelFamily')];
    const modelFilters = [...groupedFilters.makeModel.filter(f => f.type === 'model')];
    
    const result: Filter[] = [];
    
    // Process each make and group its related model families and models
    for (const makeFilter of makeFilters) {
      const parts = makeFilter.label.split(': ');
      const makeName = parts.length > 1 ? parts[1] : makeFilter.label;
      const makeNameLower = makeName.toLowerCase();
      
      // Add the make filter
      result.push(makeFilter);
      
      // Find and add related model families
      const relatedModelFamilies = modelFamilyFilters.filter(f => 
        f.data?.make?.toLowerCase() === makeNameLower
      );
      
      // Sort model families alphabetically
      relatedModelFamilies.sort((a, b) => {
        const aLabel = a.label.split(': ')[1] || '';
        const bLabel = b.label.split(': ')[1] || '';
        return aLabel.localeCompare(bLabel);
      });
      
      result.push(...relatedModelFamilies);
      
      // Remove processed families
      relatedModelFamilies.forEach(family => {
        const index = modelFamilyFilters.findIndex(f => f === family);
        if (index !== -1) modelFamilyFilters.splice(index, 1);
      });
      
      // Find and add related models
      const relatedModels = modelFilters.filter(f => 
        f.data?.make?.toLowerCase() === makeNameLower && !f.data?.modelFamily
      );
      
      // Sort models
      relatedModels.sort((a, b) => {
        const aLabel = a.label.split(': ')[1] || '';
        const bLabel = b.label.split(': ')[1] || '';
        return aLabel.localeCompare(bLabel);
      });
      
      result.push(...relatedModels);
      
      // Remove processed models
      relatedModels.forEach(model => {
        const index = modelFilters.findIndex(f => f === model);
        if (index !== -1) modelFilters.splice(index, 1);
      });
    }
    
    // Add remaining model families (sorted)
    modelFamilyFilters.sort((a, b) => {
      const aLabel = a.label.split(': ')[1] || '';
      const bLabel = b.label.split(': ')[1] || '';
      return aLabel.localeCompare(bLabel);
    });
    result.push(...modelFamilyFilters);
    
    // Add remaining models (sorted)
    modelFilters.sort((a, b) => {
      const aLabel = a.label.split(': ')[1] || '';
      const bLabel = b.label.split(': ')[1] || '';
      return aLabel.localeCompare(bLabel);
    });
    result.push(...modelFilters);
    
    return result;
  };
  
  // Get the ordered filters
  const orderedMakeModelFilters = getOrderedMakeModelFilters();
  
  // Format the grouped pills with pre-processed filters
  const renderGroupedPills = (group: Filter[], title: string) => {
    if (group.length === 0) return null;
    
    return (
      <div className={pillStyles.group.groupContainer}>
        <span className={pillStyles.group.title}>{title}:</span>
        <div className={pillStyles.group.pillsContainer}>
          {group.map((filter, index) => {
            // Extract just the value part from the label (remove the prefix like "Make: ")
            const labelParts = filter.label.split(': ');
            const rawDisplayLabel = labelParts.length > 1 ? labelParts[1] : filter.label;
            
            // Apply appropriate formatting based on filter type
            let displayLabel;
            if (filter.type === 'make') {
              displayLabel = formatMakeName(rawDisplayLabel);
            } else if (filter.type === 'modelFamily') {
              // For model families, we need to handle the label format properly
              // The label might be just the family name or have a "Make - Family" format
              displayLabel = formatModelName(rawDisplayLabel);
            } else if (filter.type === 'model') {
              displayLabel = formatModelName(rawDisplayLabel);
            } else if (filter.type === 'equipment') {
              // For equipment types, use the exact label without formatting
              displayLabel = rawDisplayLabel;
            } else {
              displayLabel = formatTitleCase(rawDisplayLabel);
            }
            
            // Choose variant based on filter type
            const pillVariant = filter.type === 'modelFamily' ? 'modelFamily' : variant;
            
            return (
              <button
                key={`${filter.type}-${index}-${displayLabel}`}
                onClick={filter.onRemove}
                type="button"
                className={cn(
                  pillStyles.base,
                  pillStyles.sizes[size],
                  pillStyles.variants[pillVariant as keyof typeof pillStyles.variants]
                )}
                data-type={filter.type}
                data-related-make={filter.data?.make || ''}
              >
                <span className="inline-flex items-center leading-none">
                  <span className="leading-none">{displayLabel}</span>
                  {filter.count !== undefined && (
                    <span className={pillStyles.count}>({filter.count})</span>
                  )}
                  <span className={pillStyles.xButton}>x</span>
                </span>
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className={pillStyles.group.container} style={{ zIndex: 40 }}>
      {orderedMakeModelFilters.length > 0 && renderGroupedPills(orderedMakeModelFilters, 'Make & Model')}
      {groupedFilters.equipment.length > 0 && renderGroupedPills(groupedFilters.equipment, 'Avionics')}
      {groupedFilters.year.length > 0 && renderGroupedPills(groupedFilters.year, 'Year')}
      {groupedFilters.price.length > 0 && renderGroupedPills(groupedFilters.price, 'Price')}
      {groupedFilters.engineTime.length > 0 && renderGroupedPills(groupedFilters.engineTime, 'Engine Time')}
      {groupedFilters.usefulLoad.length > 0 && renderGroupedPills(groupedFilters.usefulLoad, 'Useful Load')}
      {groupedFilters.specifications.length > 0 && renderGroupedPills(groupedFilters.specifications, 'Specifications')}
      {groupedFilters.status.length > 0 && renderGroupedPills(groupedFilters.status, 'Status')}
      {groupedFilters.other.length > 0 && renderGroupedPills(groupedFilters.other, 'Other')}
      
      {showClearAll && filters.length > 0 && (
        <button
          onClick={onClearAll}
          type="button"
          className={cn(
            pillStyles.base,
            pillStyles.sizes[size],
            pillStyles.variants.default,
            pillStyles.clearAllButton
          )}
        >
          <span className="inline-flex items-center leading-none">
            <span className="leading-none">Clear all</span>
          </span>
        </button>
      )}
    </div>
  );
};

// Add default export so it can be imported either way
export default FilterPills; 