import { BrowserRouter, Routes, Route, Navigate, useSearchParams, useParams } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NuqsAdapter } from 'nuqs/adapters/react';
import { ListingProvider } from './context/ListingContext';
import { ListingImagesProvider } from './contexts/ListingImagesContext';
import { ListingDetail } from './components/ListingDetail';
import { AircraftSearch } from './components/AircraftSearch';
import { FavoritesPage } from './components/FavoritesPage';

const queryClient = new QueryClient();

// RouteHandler determines whether to show the search page or direct listing detail
const RouteHandler = () => {
  const [searchParams] = useSearchParams();
  const detailId = searchParams.get('detailId');
  
  if (detailId) {
    return <ListingDetail listingId={detailId} />;
  }
  
  return <AircraftSearch />;
};

// Direct access to listing detail page
const ListingDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  return <ListingDetail listingId={id} />;
};

export const App = () => {
  return (
    <BrowserRouter>
      <NuqsAdapter>
        <QueryClientProvider client={queryClient}>
          <ListingProvider>
            <ListingImagesProvider>
              <div className="relative">
                <Routes>
                  {/* Home route with smart handling for direct detail access */}
                  <Route path="/" element={<RouteHandler />} />
                  
                  {/* Direct access to listing detail */}
                  <Route path="/listing/:id" element={<ListingDetailPage />} />
                  
                  {/* Favorites page */}
                  <Route path="/favorites" element={<FavoritesPage />} />
                  
                  {/* Catch-all redirect */}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </div>
            </ListingImagesProvider>
          </ListingProvider>
        </QueryClientProvider>
      </NuqsAdapter>
    </BrowserRouter>
  );
};

export default App;