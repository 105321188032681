import { Icon } from './Icon';
import { useState, useEffect } from 'react';
import { cn, buttonStyles } from '../lib/utils';

interface DarkModeToggleProps {
  className?: string;
  variant?: 'floating' | 'inline';
}

// Use Tailwind's built-in dark mode functionality
export const DarkModeToggle = ({ className, variant = 'floating' }: DarkModeToggleProps) => {
  // Initialize state based on current theme
  const [isDark, setIsDark] = useState(false);

  // Set up initial state on mount
  useEffect(() => {
    // Check initial dark mode preference
    const darkModePreference = localStorage.getItem('darkMode') === 'true';
    setIsDark(darkModePreference);
    if (darkModePreference) {
      document.documentElement.classList.add('dark');
    }
  }, []);

  const toggleDarkMode = () => {
    const newDarkMode = !isDark;
    setIsDark(newDarkMode);
    localStorage.setItem('darkMode', String(newDarkMode));
    document.documentElement.classList.toggle('dark');
  };

  // Different styles based on variant
  const buttonClasses = variant === 'floating'
    ? cn(
        "fixed top-2 sm:top-4 right-2 sm:right-4 p-1 sm:p-1.5",
        "rounded-full",
        "bg-white/90 dark:bg-neutral-800/90",
        "text-gray-800 dark:text-gray-200",
        "border border-gray-200 dark:border-neutral-700",
        "hover:bg-gray-50 dark:hover:bg-neutral-700",
        "transition-colors shadow-sm z-50"
      )
    : cn(
        buttonStyles.base,
        buttonStyles.rounded,
        buttonStyles.shadow,
        buttonStyles.icon.md,
        'flex items-center justify-center'
      );
    return (
      <button
        onClick={toggleDarkMode}
        className={cn(buttonClasses, className)}
        title={isDark ? "Switch to light mode" : "Switch to dark mode"}
        aria-label={isDark ? "Switch to light mode" : "Switch to dark mode"}
      >
        {isDark ? (
          <Icon name="Sun" size={16} className={variant === 'floating' ? "sm:w-4 sm:h-4" : "h-4 w-4"} />
        ) : (
          <Icon name="Moon" size={16} className={variant === 'floating' ? "sm:w-4 sm:h-4" : "h-4 w-4"} />
        )}
      </button>
    );
}; 