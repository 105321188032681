import type { CardMetric } from './api';
import { RANGE_CONFIGS, getRangeDefaults } from '../shared/range-config.js';
import type { EquipmentCategory } from './api';

export type SortOption = 'relevance' | 'price-asc' | 'price-desc' | 'year-asc' | 'year-desc' | 'date-listed' | 'updated-desc' | 'updated-asc';

export interface QueryState {
  // Search and filters
  search: string;
  makes: string[];
  modelFamilies: string[];
  models: string[];
  priceRange: [number, number];
  yearRange: [number, number];
  engineTimeRange: [number, number];
  usefulLoadRange: [number, number];
  showZeroPrice: boolean;
  showSold: boolean;
  equipment: Record<EquipmentCategory, boolean>;
  
  // New filters
  condition: string;
  saleStatus: string;
  intendedUse: string[];
  category: string;
  
  // Pagination
  page: number;
  itemsPerPage: number;
  
  // Sort and view options
  sort: SortOption;
  columnCount: 1 | 2 | 3 | 4;
}

export const DEFAULT_QUERY_STATE: QueryState = {
  search: '',
  makes: [],
  modelFamilies: [],
  models: [],
  priceRange: getRangeDefaults('priceRange'),
  yearRange: getRangeDefaults('yearRange'),
  engineTimeRange: getRangeDefaults('engineTimeRange'),
  usefulLoadRange: getRangeDefaults('usefulLoadRange'),
  showZeroPrice: false,
  showSold: false,
  equipment: {
    autopilot: false,
    gps: false,
    radio: false,
    audioPanel: false,
    weather: false,
    traffic: false,
    adsbOut: false,
    adsbIn: false,
    pfd: false,
    mfd: false,
    emsEis: false
  },
  
  // Default values for new filters
  condition: '',
  saleStatus: '',
  intendedUse: [],
  category: '',
  
  page: 1,
  itemsPerPage: 20,
  sort: 'relevance',
  columnCount: 3
};

export type QueryStateKey = keyof QueryState;

// Add a type guard
export function isCardMetric(value: string): value is CardMetric {
  return [
    'registration',
    'year',
    'totalTime',
    'engineTime',
    'propTime',
    'flightRules',
    'make',
    'model',
    'serialNumber',
    'usefulLoad'
  ].includes(value) || value.startsWith('equipment.');
}

// Add a type assertion function
export function assertValidSort(sort: string): asserts sort is QueryState['sort'] {
  if (!['relevance', 'price', 'year'].includes(sort)) {
    throw new Error(`Invalid sort value: ${sort}`);
  }
} 